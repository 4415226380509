import React from "react";
import Header from "./Header/Header";

const Loading = () => {
  return (
    <div className="loadingContainer">
      <Header />
      <div className="loading">
        <h2>Loading...</h2>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};
export default Loading;
