import axios from "axios";
import authHeader from "./auth-header";
import * as config from "../config/config";

const urlVendor = config.urlServer + "/vendor";
const urlUser = config.urlServer + "/user";
const urlProcess = config.urlServer + "/process";
const urlDocuments = config.urlServer + "/documents";
const urlInfo = config.urlServer + "/info";
const urlTemplate = config.urlServer + "/templates";

class UserService {
  // VENDOR
  postCreateVendor(form) {
    return axios.post(urlVendor + "/create", form, { headers: authHeader() });
  }
  postEditVendor(form) {
    return axios.post(urlVendor + "/edit", form, { headers: authHeader() });
  }
  getAllVendor() {
    return axios.get(urlVendor + "/getall", { headers: authHeader() });
  }
  getAllCategoryVendor() {
    return axios.get(urlVendor + "/getallcategory", { headers: authHeader() });
  }
  getVendorByUserID(id) {
    return axios.get(urlVendor + "/get", { params: id, headers: authHeader() });
  }

  // USER
  postCreateUser(form) {
    return axios.post(urlUser + "/create", form, { headers: authHeader() });
  }
  postModifyUser(form) {
    return axios.post(urlUser + "/update", form, { headers: authHeader() });
  }
  postChangeUserPass(form) {
    return axios.post(urlUser + "/changePass", form, { headers: authHeader() });
  }
  postResetUserPass(form) {
    return axios.post(urlUser + "/resetpass", form, { headers: authHeader() });
  }
  getAllUser() {
    return axios.get(urlUser + "/getall", { headers: authHeader() });
  }
  getUser(id) {
    return axios.get(urlUser + "/getuser", { params: id, headers: authHeader() });
  }

  // PROCESS
  getAllProcess() {
    return axios.get(urlProcess + "/getall", { headers: authHeader() });
  }
  postCreateProcess(form) {
    return axios.post(urlProcess + "/create", form, { headers: authHeader() });
  }
  CancelarProceso(form) {
    return axios.post(urlProcess + "/cancelar", form, {
      headers: authHeader(),
    });
  }
  BloquearProceso(form) {
    return axios.post(urlProcess + "/bloquear", form, {
      headers: authHeader(),
    });
  }
  CancelarProcesoSinDeshomologar(form) {
    return axios.post(urlProcess + "/cancelarsindes", form, {
      headers: authHeader(),
    });
  }
  DesbloquearProceso(form) {
    return axios.post(urlProcess + "/desbloquear", form, {
      headers: authHeader(),
    });
  }
  getProcess(form) {
    return axios.get(urlProcess + "/get", {
      params: form,
      headers: authHeader(),
    });
  }

  // DOCUMENTS
  getDocuments(form) {
    return axios.get(urlDocuments + "/get", {
      params: form,
      headers: authHeader(),
    });
  }

  createDocument(form) {
    return axios.post(urlDocuments + "/create", form, {
      headers: authHeader(),
    });
  }
  deleteDocument(form) {
    return axios.post(urlDocuments + "/delete", form, {
      headers: authHeader(),
    });
  }
  getAllDocuments() {
    return axios.get(urlDocuments + "/getAllDoc", {
      headers: authHeader(),
    });
  }
  uploadFile(file) {
    return axios.post(urlDocuments + "/upload", file, {
      headers: authHeader(),
    });
  }
  downloadFile(form) {
    return axios.get(urlDocuments + "/download", {
      params: form,
      headers: authHeader(),
    });
  }
  updateDocument(form) {
    return axios.post(urlDocuments + "/update", form, {
      headers: authHeader(),
    });
  }
  updateDocumentNA(form) {
    return axios.post(urlDocuments + "/updateNA", form, {
      headers: authHeader(),
    });
  }
  updateDocCloseProcess(form) {
    return axios.post(urlDocuments + "/updateClose", form, {
      headers: authHeader(),
    });
  }
  notifyVendorDoc(form) {
    return axios.post(urlDocuments + "/notifyVendor", form, {
      headers: authHeader(),
    });
  }
  
  sendMailToComplianceAllDocUploaded(form) {
    return axios.post(urlDocuments + "/notifyCompliance", form, {
      headers: authHeader(),
    });
  }
  modificarFecProxRenov(form) {
    return axios.post(urlDocuments + "/modifyFecProxRenov", form, {
      headers: authHeader(),
    });
  }

  // TEMPLATE
  getAllTemplate() {
    return axios.get(urlTemplate + "/getAll", { headers: authHeader() });
  }
  createNewTemplate(form) {
    return axios.post(urlTemplate + "/createTemplate", form, {
      headers: authHeader(),
    });
  }
  deleteTemplate(form) {
    return axios.post(urlTemplate + "/deleteTemplate", form, {
      headers: authHeader(),
    });
  }

  // INFO
  sendVendorInfo(form) {
    return axios.post(urlInfo, form, { headers: authHeader() });
  }
  editVendorInfo(form) {
    return axios.post(urlInfo + "/edit", form, { headers: authHeader() });
  }
}
export default new UserService();
