// REACT
import React, { Component } from "react";
import { connect } from "react-redux";
// LIBRARIES
import { Redirect } from "react-router-dom";
// COMPONENTS
import AdminHome from "./Homes/AdminHome";
import UserHome from "./Homes/UserHome";
import store from "../store";
import * as CONFIG from "../config/config";

class Home extends Component {
  render() {
    const currentUser = store.getState().auth.user;

    if (!currentUser) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="homeContainer">
        {[CONFIG.ROLES.ADMIN, CONFIG.ROLES.USER].includes(currentUser.role) ? (
          <AdminHome />
        ) : (
          <UserHome />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Home);
