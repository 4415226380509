// REACT
import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
// LIBRARIES
import { Table, Modal, Button } from "react-bootstrap";
// COMPONENTS
import store from "../../store";
import Loading from "../loading";
import Header from "../Header/Header";
import { getProcess } from "../../actions/process";
import { history } from "../../helpers/history"

const UserHome = () => {
  const id = store.getState().auth.user?.id;
  const username = store.getState().auth.user?.username;
  const process = store.getState().process.process?.length;

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleShow = () => setShow(true);
  const handleShow2 = () => setShow2(true);

  const dispatch = useDispatch();



  useEffect(() => {
    dispatch(getProcess({ id: id }));
  }, [dispatch, id]);

  function clickOnProcess(username, id, estado) {

    if (["Bloqueado"].includes(estado)) {
      handleShow();
    } else if (["Cancelado"].includes(estado)) {
      handleShow2();
    }
    else {
      history.push("/procesos/" + username + "/" + id);
    }
  }

  const loading = store.getState().loading;
  return loading ? (
    <Loading />
  ) : (
    <div className="pageContainer">
      <Header />
      {/* <h6>ID: {id} </h6>
      <h6>Usuario: {username}</h6>
      <h6>Rol: {role}</h6> */}
      <h2>Procesos de homologación</h2>
      {process ? (
        <div>
          <Table className="styled-table" responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Proveedor</th>
                <th>Tipo_Homologacion</th>
                <th>Válido_Hasta</th>
                <th>Estado Proceso</th>
                <th>CIF/NIF</th>
                <th>Razón Social</th>
                <th>Denom. Comercial</th>
                <th>Categoría Proveedor</th>
                <th>Estado Proveedor</th>
                <th>Owner</th>
                <th>Mail Contacto</th>
                <th> {">"} </th>
              </tr>
            </thead>
            <tbody>
              {store.getState().process.process.map((proc, index) => (
                <tr
                  className="clickable"
                  key={proc.idProceso}
                  onClick={() =>
                    clickOnProcess(username, index + 1, proc.estado)
                  }
                >
                  <td>{index + 1}</td>
                  <td>{proc.nombreProveedor}</td>
                  <td>{proc.tipoProceso}</td>
                  <td>{proc.fecProxRenovacion.substring(0, 10)}</td>
                  <td>{proc.estado}</td>
                  <td>{proc.cifnif}</td>
                  <td>{proc.razonSocial}</td>
                  <td>{proc.denomComercial}</td>
                  <td>{proc.vendType}</td>
                  <td>{proc.estadoProveedor}</td>
                  <td>{proc.correoOwner}</td>
                  <td>{proc.mailContacto}</td>
                  <td> {">"} </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="noProcesso">
          <h5>
            Actualmente no tiene procesos de homologación activos, será
            notificado por correo cuando le sea asignado un proceso de
            homologación.
          </h5>
        </div>
      )}
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Proceso bloqueado
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Su proceso ha sido bloqueado por haber excedido el tiempo para
          adjuntar la documentación o por decisión de los administradores. 
        </Modal.Body>
        <Modal.Body>
          Por favor, pongase en contacto con un administrador en el correo compliance@redwoodcorp.com para desbloquear
          su proceso.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn-create-form"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal centered show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Proceso cancelado
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>          
          Este proceso ha sido cancelado por un administrador.
        </Modal.Body>
        <Modal.Body>
          Por favor, pongase en contacto con un administrador en el correo compliance@redwoodcorp.com para determinar si 
          su proceso está correctamente cancelado.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn-create-form"
            onClick={handleClose2}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};
export default connect(mapStateToProps)(UserHome);
