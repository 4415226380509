// REACT
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// LIBARIES
import { Form, Button, Row, Col } from "react-bootstrap";
//COMPONENTS
import store from "../../store";
import Header from "../Header/Header";
import ProteccionDeDatosPDF from "../../images/GRC_Protecciondedatos.pdf";
import SolicitudHomologacionPDF from "../../images/GRC_SolicitudHomologacion_herramienta.pdf";
import { sendVendorInfo } from "../../actions/info";
import { getVendorByUserID } from "../../actions/vendor";
import { history } from "../../helpers/history";
// FUNCTIONS
import * as lib from "../../functions/functions";

const Info = () => {
  const currentUser = store.getState().auth.user;
  lib.userAccessbyRol(currentUser, "VENDOR");
  const dispatch = useDispatch();
  const blnprimerLogin = !store.getState().auth.user?.primerLogin;

  useEffect(() => {
    if (blnprimerLogin) {
      history.push("/");
    }
  }, [currentUser, blnprimerLogin]);

  useEffect(() => {
    dispatch(getVendorByUserID(store.getState().auth.user));
  }, [dispatch]);

  const proveedor = store.getState().vendor?.vendor[0];
  const categoria = proveedor ? proveedor.categoriaProveedor : "";
  const [blnDatosPers, setblnDatosPers] = useState(true);

  const blnComercializadora = (categoria === "Broker") ? false : true;
  const blnOtros = (categoria === "Otros servicios") ? false : true;
    

  const [formDataProv, setformDataProv] = useState({
    
        
    userID: store.getState().auth.user.id,

    RazonSocial: "",
    CIFNIF: "",
    DenComercial: "",
    IBAN: "",
    Domicilio: "",
    Localidad: "",
    Provincia: "",
    CP: "",
    Telefono: "",
    PaginaWeb: "",

    ZonaTrabajo: "",
    Otros: "",
    Datospersonales: "",
    Detalle: "",

    Nombre_contacto: "",
    Apellido1_contacto: "",
    Apellido2_contacto: "",
    Mail_contacto: "",
    Telefono_contacto: "",

    Nombre_firmante: "",
    DatosRepresentacion_firmante: "",
    Apellido1_firmante: "",
    Apellido2_firmante: "",
    Mail_firmante: "",
    Telefono_firmante: "",

    ProteccionDatos: false,
    Categoria: "",
  });




  const [formGDPR_A, setformGDPR_A] = useState({
    Q1: "",
    Q2: "",
    Q3: "",
    Q4: "",
    Q5: "",
    Q6: "",
    Q7: "",
    Q8: "",
    Q9: "",
    Q10: "",
  });
  const [formGDPR_B, setformGDPR_B] = useState({
    Q1: "",
    Q2: "",
    Q3: "",
    Q4: "",
    Q5: "",
    Q6: "",
    Q7: "",
    Q8: "",
    Q9: "",
    Q10: "",
    Q11: "",
    Q12: "",
    Q13: "",
    Q14: "",
    Q15: "",
    Q16: "",
  });

  function handleSubmit(e) {
    e.preventDefault();

    let form = {};
    if (formDataProv.Categoria === "Broker") {
       form = { ...formDataProv, ...formGDPR_A };
     } else {
       form = { ...formDataProv, ...formGDPR_B };
     }
    dispatch(sendVendorInfo(form));
  }

  

  return (
    <div className="pageContainer">
      <Header />
      <div className="formContainer">
        <h2>Formulario proveedores</h2>
        <Form onSubmit={handleSubmit} className="vendorForm">
          <h5>Datos de la Empresa</h5>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Row>
              <Col>
                <Form.Label>Razón Social</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Razón Social (incluyendo titularidad jurídica)"
                  required="required"
                  value={formDataProv.RazonSocial}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      RazonSocial: e.target.value.slice(0,100),
                      Categoria: store.getState().vendor?.vendor[0].categoriaProveedor,
                      Mail_contacto: store.getState().vendor?.vendor[0].correoProveedor,
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>CIF/NIF</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="CIF/NIF"
                  required="required"
                  value={formDataProv.CIFNIF}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      CIFNIF: e.target.value.slice(0,10),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Denominación Comercial</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Denominación Comercial"
                  required="required"
                  value={formDataProv.DenComercial}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      DenComercial: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
            </Row>
            <Form.Label>Cuenta Bancaria (IBAN)</Form.Label>
            <Form.Control
              type="text"
              placeholder="IBAN"
              required="required"
              value={formDataProv.IBAN}
              onChange={(e) =>
                setformDataProv({
                  ...formDataProv,
                  IBAN: e.target.value.slice(0,100),
                })
              }
            />
            <Row>
              <Col>
                <Form.Label>Domicilio</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Domicilio (calle, número, portal...)"
                  required="required"
                  value={formDataProv.Domicilio}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Domicilio: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Localidad</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Localidad"
                  required="required"
                  value={formDataProv.Localidad}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Localidad: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Provincia</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Provincia"
                  required="required"
                  value={formDataProv.Provincia}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Provincia: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Código Postal</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Código Postal"
                  required="required"
                  value={formDataProv.CP}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      CP: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Teléfono contacto"
                  required="required"
                  value={formDataProv.Telefono}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Telefono: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Página web</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Página web"
                  //required="required"
                  value={formDataProv.PaginaWeb}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      PaginaWeb: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
            </Row>
          </Form.Group>

          <h5>Datos del Servicio</h5>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Group hidden={blnComercializadora}>
              <Form.Label>Zona de trabajo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Zona de Trabajo"
                required={!blnComercializadora}
                value={formDataProv.ZonaTrabajo}
                onChange={(e) =>
                  setformDataProv({
                    ...formDataProv,
                    ZonaTrabajo: e.target.value.slice(0,100),
                  })
                }
              />
            </Form.Group>
            {/* { <Form.Group hidden={true}>
              <Form.Label>hola</Form.Label>
              <Form.Control
                type="text"
                //placeholder={formDataProv.Categoria}
                value={formDataProv.Categoria}
                onChange={(e) =>
                  setformDataProv({
                    ...formDataProv,
                    Categoria: categoria,
                  })
                }
              />
            </Form.Group> } */}
            <Form.Group hidden={blnOtros}>
              <Form.Label>Otros</Form.Label>
              <Form.Control
                type="text"
                placeholder="Tipo de servicio"
                required={!blnOtros}
                value={formDataProv.Otros}
                onChange={(e) =>
                  setformDataProv({
                    ...formDataProv,
                    Otros: e.target.value.slice(0,100),
                  })
                }
              />
            </Form.Group>
            <Form.Label>Acceso a datos personales</Form.Label>
            <Form.Select
              className="datServicioMidWidth"
              required="required"
              value={formDataProv.Datospersonales}
              onChange={(e) => {
                setformDataProv({
                  ...formDataProv,
                  Datospersonales: e.target.value,
                });

                if (e.target.value === "Si") {
                  setblnDatosPers(false);
                } else {
                  setblnDatosPers(true);
                }
              }}
            >
              <option value="" hidden>
                ---
              </option>
              <option value="Si">Si</option>
              <option value="No">No</option>
            </Form.Select>
            <Form.Group hidden={blnDatosPers}>
              <Form.Label>Detalle datos personales</Form.Label>
              <Form.Control
                type="text"
                placeholder="Especificar qué datos personales trata: nombre, DNI, datos de contacto, situación laboral, etc..."
                required={!blnDatosPers}
                value={formDataProv.Detalle}
                onChange={(e) =>
                  setformDataProv({
                    ...formDataProv,
                    Detalle: e.target.value.slice(0,100),
                  })
                }
              />
            </Form.Group>
          </Form.Group>
          <h5>Datos de contacto</h5>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Row>
              <Col>
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  required="required"
                  value={formDataProv.Nombre_contacto}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Nombre_contacto: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Apellido</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Primer Apellido"
                  required="required"
                  value={formDataProv.Apellido1_contacto}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Apellido1_contacto: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Apellido 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Segundo Apellido"
                  required="required"
                  value={formDataProv.Apellido2_contacto}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Apellido2_contacto: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Correo proveedor</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Dirección de correo"
                  required="required"
                  value={formDataProv.Mail_contacto}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Mail_contacto: e.target.value.slice(0,100),
                      CorreoProveedor: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Teléfono de Contacto"
                  required="required"
                  value={formDataProv.Telefono_contacto}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Telefono_contacto: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
            </Row>
          </Form.Group>
          <h5>Datos de firmante del contrato</h5>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Row>
              <Col>
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  required="required"
                  value={formDataProv.Nombre_firmante}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Nombre_firmante: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Apellido</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Primer Apellido"
                  required="required"
                  value={formDataProv.Apellido1_firmante}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Apellido1_firmante: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Apellido 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Segundo Apellido"
                  required="required"
                  value={formDataProv.Apellido2_firmante}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Apellido2_firmante: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
            </Row>
            <Form.Label>Datos de representación</Form.Label>
            <Form.Control
              className="formDatosRep"
              type="text"
              placeholder="(cargo, notario, protocolo, fecha)"
              required="required"
              value={formDataProv.DatosRepresentacion_firmante}
              onChange={(e) =>
                setformDataProv({
                  ...formDataProv,
                  DatosRepresentacion_firmante: e.target.value.slice(0,100),
                })
              }
            />
            <Row>
              <Col>
                <Form.Label>Correo firmante</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Dirección de correo"
                  required="required"
                  value={formDataProv.Mail_firmante}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Mail_firmante: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Teléfono"
                  required="required"
                  value={formDataProv.Telefono_firmante}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Telefono_firmante: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
            </Row>
          </Form.Group>
          <h5>Reglamento General de Protección de Datos</h5>
          {!blnComercializadora ? (
            <Form.Group className="mb-3" controlId="formBasicName">
              <Row>
                <b>
                  Medidas de Seguridad y Política de seguridad de la Información{" "}
                </b>
                <Col className="textGDPR">
                  <Form.Label>
                    Confirmación e identificación de: i) los activos implicados
                    en el tratamiento de los datos personales y control de los
                    soportes de almacenamiento (incluyendo una gestión de
                    soportes extraíbles, dispositivos móviles como teléfonos,
                    pendrives, tablets, portátiles, etc.) y ii) los controles de
                    acceso a sistemas y aplicaciones y de la relación
                    actualizada de los usuarios y perfiles de usuarios, y los
                    accesos autorizados para cada uno de ellos.
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={!blnComercializadora}
                    value={formGDPR_A.Q1}
                    onChange={(e) => {
                      setformGDPR_A({
                        ...formGDPR_A,
                        Q1: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col className="textGDPR">
                  <Form.Label>
                    Confirmación de la existencia de contraseñas para el acceso
                    a los datos personales almacenados en sistemas electrónicos.
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={!blnComercializadora}
                    value={formGDPR_A.Q2}
                    onChange={(e) => {
                      setformGDPR_A({
                        ...formGDPR_A,
                        Q2: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col className="textGDPR">
                  <Form.Label>
                    Cuenta con un procedimiento de gestión de copias de
                    seguridad y recuperación de la información. Identificación y
                    periodicidad de las mismas.
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={!blnComercializadora}
                    value={formGDPR_A.Q3}
                    onChange={(e) => {
                      setformGDPR_A({
                        ...formGDPR_A,
                        Q3: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col className="textGDPR">
                  <Form.Label>
                    "Identificación del sistema de protección ante códigos
                    maliciosos. i) Identificación del sistema de antivirus que
                    garantice en la medida posible el robo y destrucción de la
                    información y datos personales. ii) Identificación de la
                    existencia de un firewall activado en aquellos ordenadores y
                    dispositivos en los que se realice el almacenamiento y/o
                    tratamiento de datos personales que pueda identificar los
                    accesos no autorizados"
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={!blnComercializadora}
                    value={formGDPR_A.Q4}
                    onChange={(e) => {
                      setformGDPR_A({
                        ...formGDPR_A,
                        Q4: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <b>Incidentes de seguridad</b>
                <Col className="textGDPR">
                  <Form.Label>
                    Dispone de procedimientos de gestión de incidentes que
                    incluyan el reporte al responsable de tratamiento sin
                    dilación indebida, en caso de que se produzca un incidente
                    de seguridad que afecte a los datos de carácter personal.
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={!blnComercializadora}
                    value={formGDPR_A.Q5}
                    onChange={(e) => {
                      setformGDPR_A({
                        ...formGDPR_A,
                        Q5: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col className="textGDPR">
                  <Form.Label>
                    Dentro del procedimiento de gestión de incidentes, en el
                    reporte a realizar al responsable de tratamiento, se
                    describe la naturaleza de la incidencia, el número
                    aproximado y categorías de datos, la información de contacto
                    del DPO, la descripción de las medidas propuestas para
                    remediar o mitigar la incidencia así como las posibles
                    consecuencias de dicha incidencia.
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={!blnComercializadora}
                    value={formGDPR_A.Q6}
                    onChange={(e) => {
                      setformGDPR_A({
                        ...formGDPR_A,
                        Q6: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <b>Empleados</b>
                <Col className="textGDPR">
                  <Form.Label>
                    Los empleados que van a acceder a los datos personales del
                    cliente firman las pertinentes cláusulas de
                    confidencialidad.
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={!blnComercializadora}
                    value={formGDPR_A.Q7}
                    onChange={(e) => {
                      setformGDPR_A({
                        ...formGDPR_A,
                        Q7: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <b>Subcontrataciones</b>
                <Col className="textGDPR">
                  <Form.Label>
                    En el caso que el servicio lleve aparejadas
                    subcontrataciones de terceros, es decir, el Broker cuente
                    con terceras empresas en las que delega el tratamiento de
                    datos personales responsabilidad de Redwood ¿Se encuentra
                    regulada dicha relación desde un punto de vista de
                    protección de datos? ¿Cuenta con los respectivos acuerdos en
                    materia de protección de datos?
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={!blnComercializadora}
                    value={formGDPR_A.Q8}
                    onChange={(e) => {
                      setformGDPR_A({
                        ...formGDPR_A,
                        Q8: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <b>Transferencias internacionales de datos</b>
                <Col className="textGDPR">
                  <Form.Label>
                    "Si con motivo de la prestación de los servicios, los datos
                    personales pueden ser accesibles desde algún país que no sea
                    alguno de los siguientes países: miembros de la UE, Suiza,
                    Canadá, Argentina, Guernsey, Isla de Man, Jersey, Islas
                    Feroe, Andorra, Israel, Uruguay y Nueva Zelanda o bien se
                    subcontratan servicios con terceros cuyos servicios son
                    prestados desde fuera de la UE. ¿Cuenta con las garantías
                    adecuadas recogidas en RGPD?
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={!blnComercializadora}
                    value={formGDPR_A.Q9}
                    onChange={(e) => {
                      setformGDPR_A({
                        ...formGDPR_A,
                        Q9: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <b>Tratamiento de datos especialmente protegidos</b>
                <Col className="textGDPR">
                  <Form.Label>
                    ¿Trata datos de salud, antecedentes penales o cualquier tipo
                    de datos categorizados como especialmente protegidos por la
                    normativa aplicable?
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={!blnComercializadora}
                    value={formGDPR_A.Q10}
                    onChange={(e) => {
                      setformGDPR_A({
                        ...formGDPR_A,
                        Q10: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          ) : (
            <Form.Group className="mb-3" controlId="formBasicName">
              <Row>
                <b>Gobierno y control </b>
                <Col className="textGDPR">
                  <Form.Label>
                    ¿Dispone de un DPO (Delegado de Protección de Datos) o de
                    una figura responsable de protección de datos?
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={blnComercializadora}
                    value={formGDPR_B.Q1}
                    onChange={(e) => {
                      setformGDPR_B({
                        ...formGDPR_B,
                        Q1: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <b>Cifrado de la información</b>
                <Col className="textGDPR">
                  <Form.Label>
                    ¿Ha procedido a la identificación de las técnicas de cifrado
                    utilizadas?
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={blnComercializadora}
                    value={formGDPR_B.Q2}
                    onChange={(e) => {
                      setformGDPR_B({
                        ...formGDPR_B,
                        Q2: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <b>Certificaciones y auditorías</b>
                <Col className="textGDPR">
                  <Form.Label>
                    ¿Dispone de informes de Auditoría recientes sobre protección
                    de datos (dos últimos años) realizadas por un tercero
                    confiable que evidencien buenas prácticas en materia de
                    protección de datos?
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={blnComercializadora}
                    value={formGDPR_B.Q3}
                    onChange={(e) => {
                      setformGDPR_B({
                        ...formGDPR_B,
                        Q3: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <b>Evaluaciones de impacto (PIAs)</b>
                <Col className="textGDPR">
                  <Form.Label>
                    ¿Ha remitido evidencia de la/s Evaluación/es de Impacto
                    llevadas a cabo (PIAs) para el tratamiento/s de datos
                    afectado/s ?
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={blnComercializadora}
                    value={formGDPR_B.Q4}
                    onChange={(e) => {
                      setformGDPR_B({
                        ...formGDPR_B,
                        Q4: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <b>Plan de continuidad de negocio - BCP </b>
                <Col className="textGDPR">
                  <Form.Label>
                    Cuenta con un Plan de Continuidad de negocio (BCP -Business
                    Continuity Plan)
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={blnComercializadora}
                    value={formGDPR_B.Q5}
                    onChange={(e) => {
                      setformGDPR_B({
                        ...formGDPR_B,
                        Q5: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <b>Medidas de Seguridad y Seguridad de la Información</b>
                <Col className="textGDPR">
                  <Form.Label>
                    Dispone de una política actualizada de seguridad de la
                    información
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={blnComercializadora}
                    value={formGDPR_B.Q6}
                    onChange={(e) => {
                      setformGDPR_B({
                        ...formGDPR_B,
                        Q6: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col className="textGDPR">
                  <Form.Label>
                    Cuenta con una Política de seguridad de dispositivos móviles
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={blnComercializadora}
                    value={formGDPR_B.Q7}
                    onChange={(e) => {
                      setformGDPR_B({
                        ...formGDPR_B,
                        Q7: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col className="textGDPR">
                  <Form.Label>
                    Cuenta con un procedimiento de gestión de copias de
                    seguridad y recuperación de la información
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={blnComercializadora}
                    value={formGDPR_B.Q8}
                    onChange={(e) => {
                      setformGDPR_B({
                        ...formGDPR_B,
                        Q8: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col className="textGDPR">
                  <Form.Label>
                    Ha procedido a la identificación del sistema de protección
                    ante códigos maliciosos
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={blnComercializadora}
                    value={formGDPR_B.Q9}
                    onChange={(e) => {
                      setformGDPR_B({
                        ...formGDPR_B,
                        Q9: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <b>Incidentes de seguridad</b>
                <Col className="textGDPR">
                  <Form.Label>
                    Dispone de procedimientos de gestión de incidentes que
                    incluyan el reporte al responsable de tratamiento sin
                    dilación indebida, en caso de que se produzca un incidente
                    de seguridad que afecte a los datos de carácter personal.
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={blnComercializadora}
                    value={formGDPR_B.Q10}
                    onChange={(e) => {
                      setformGDPR_B({
                        ...formGDPR_B,
                        Q10: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col className="textGDPR">
                  <Form.Label>
                    Dentro del procedimiento de gestión de incidentes, en el
                    reporte a realizar al responsable de tratamiento, se
                    describe la naturaleza de la incidencia, el número
                    aproximado y categorías de datos, la información de contacto
                    del DPO, la descripción de las medidas propuestas para
                    remediar o mitigar la incidencia así como las posibles
                    consecuencias de dicha incidencia.
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={blnComercializadora}
                    value={formGDPR_B.Q11}
                    onChange={(e) => {
                      setformGDPR_B({
                        ...formGDPR_B,
                        Q11: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <b>Empleados</b>
                <Col className="textGDPR">
                  <Form.Label>
                    Los empleados del tercero que vayan a acceder a los datos
                    personales del cliente firman las pertinentes cláusulas de
                    confidencialidad.
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={blnComercializadora}
                    value={formGDPR_B.Q12}
                    onChange={(e) => {
                      setformGDPR_B({
                        ...formGDPR_B,
                        Q12: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <b>Análisis de riesgos</b>
                <Col className="textGDPR">
                  <Form.Label>
                    Ha remitido evidencia de los análisis de riesgos de los
                    tratamientos llevados a cabo
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={blnComercializadora}
                    value={formGDPR_B.Q13}
                    onChange={(e) => {
                      setformGDPR_B({
                        ...formGDPR_B,
                        Q13: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <b>Subcontrataciones</b>
                <Col className="textGDPR">
                  <Form.Label>
                    En el caso que el servicio lleve aparejadas
                    subcontrataciones de terceros, ¿Se encuentra regulada dicha
                    relación desde un punto de vista de protección de datos?
                    ¿Cuenta con los respectivos acuerdos en materia de
                    protección de datos?
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={blnComercializadora}
                    value={formGDPR_B.Q14}
                    onChange={(e) => {
                      setformGDPR_B({
                        ...formGDPR_B,
                        Q14: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <b>Transferencias internacionales de datos</b>
                <Col className="textGDPR">
                  <Form.Label>
                    "Si con motivo de la prestación de los servicios, los datos
                    personales pueden ser accesibles desde algún país que no sea
                    alguno de los siguientes países: miembros de la UE, Suiza,
                    Canadá, Argentina, Guernsey, Isla de Man, Jersey, Islas
                    Feroe, Andorra, Israel, Uruguay y Nueva Zelanda o bien se
                    subcontratan servicios con terceros cuyos servicios son
                    prestados desde fuera de la UE. ¿Cuenta con las garantías
                    adecuadas recogidas en RGPD?
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={blnComercializadora}
                    value={formGDPR_B.Q15}
                    onChange={(e) => {
                      setformGDPR_B({
                        ...formGDPR_B,
                        Q15: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <b>Tratamiento de datos especialmente protegidos</b>
                <Col className="textGDPR">
                  <Form.Label>
                    ¿Trata datos de salud, antecedentes penales o cualquier tipo
                    de datos categorizados como especialmente protegidos por la
                    normativa aplicable?
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Select
                    required={blnComercializadora}
                    value={formGDPR_B.Q16}
                    onChange={(e) => {
                      setformGDPR_B({
                        ...formGDPR_B,
                        Q16: e.target.value,
                      });
                    }}
                  >
                    <option value="" hidden>
                      ---
                    </option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                    <option value="-1">N/A</option>
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          )}

          <Form.Group className="mb-3" controlId="formBasicName">
            <br />
            <Form.Check
              required="required"
              inline
              type="checkbox"
              name="proteccionDatos"
              id="proteccionDatos"
              value={formDataProv.ProteccionDatos}
              onChange={(e) =>
                setformDataProv({
                  ...formDataProv,
                  ProteccionDatos: !formDataProv.ProteccionDatos,
                })
              }
            />
            Acepto la{" "}
            <a href={SolicitudHomologacionPDF} rel="noopener noreferrer" target="_blank">
              solicitud de homologación{" "}
            </a>
            y medidas de{" "}
            <a href={ProteccionDeDatosPDF} rel="noopener noreferrer" target="_blank">
              protección de datos
            </a>
          </Form.Group>
          <Button variant="secondary" type="submit" className="btn-create-form">
           Guardar
          </Button>
        </Form>
      </div>
    </div>
  );
};
export default Info;
