// REACT
import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
// LIBRARIES
import { Table, Form, Row, Col, Modal, Button } from "react-bootstrap";
// COMPONENTS
import store from "../../store";
import Loading from "../loading";
import Header from "../Header/Header";
import {
  getAllProcess,
  cancelarProceso,
  desbloquearProceso,
} from "../../actions/process";
import { history } from "../../helpers/history";


const AdminHome = () => {
  
  const dispatch = useDispatch();

  const [procesosFiltrados, setProcesosFiltrados] = useState();

  const [formMantenerFiltros, setformMantenerFiltros] = useState({
    name: "",
    cif: "",
    cat: "",
    razonsocial: "",
    denomcomercial: "",
    owner: "",
    mailcontacto: "",
    estadoproceso: "",
    estadoproveedor: "",
  });

  const [idProcesoPulsado, setidProcesoPulsado] = useState();

  
 //let runOnce = true;

  const [time, setTime] = useState(Date.now());

  

    useEffect(() => {
      let runOnce = true;
      if (runOnce) {
       runOnce = false; 
        const interval = setInterval(() => setTime(Date.now()), 1000 );
       return () => {
         clearInterval(interval);
       }};
    }, []);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleShow = () => setShow(true);
  const handleShow2 = () => setShow2(true);

  function CancelarProceso() {
    dispatch(cancelarProceso({ idProcesoPulsado }));
    const index = procesosFiltrados.findIndex((proc) => proc.idProceso === idProcesoPulsado)
    procesosFiltrados[index].estado="Cancelado";
    const procesos2 = store
      .getState()
      .process?.process?.filter((proc) =>
        ["En proceso", "Bloqueado"].includes(proc.estado)
      );
    setProcesosFiltrados(procesos2);
    handleClose();
  }
  function DesbloquearProceso() {
    dispatch(desbloquearProceso({ idProcesoPulsado }));
    const index = procesosFiltrados.findIndex((proc) => proc.idProceso === idProcesoPulsado)
    procesosFiltrados[index].estado="En proceso";
    handleClose();
  }
  
  const loading = store.getState().loading;

  useEffect(() => {
    dispatch(getAllProcess());
  }, [dispatch]);

  const procesos = store
    .getState()
    .process?.process?.filter((proc) =>
      ["En proceso", "Bloqueado"].includes(proc.estado)
    );

  useEffect(() => {
    const procesos2 = store
      .getState()
      .process?.process?.filter((proc) =>
        ["En proceso", "Bloqueado"].includes(proc.estado)
      );
    setProcesosFiltrados(procesos2);
  }, [loading]);

  function clickOnProcess(nombProv, idProceso, estado) {
    const procesos = store.getState().process.process;
    const filt = procesos.filter((proc) => proc.nombreProveedor === nombProv);
    const index = filt.findIndex((proc) => proc.idProceso === idProceso) + 1;
    setidProcesoPulsado(idProceso);
    if (estado === "Bloqueado") {
      handleShow();
    } else if (estado === "Cancelado") {
      handleShow2();
    } 
    else {
      history.push("/procesos/" + nombProv + "/" + index);
    }
  }

  function filterProcesosCIF(cif,name,cat,razonsocial,denomcomercial,owner,mailcontacto,estadoproceso,estadoproveedor) {
    const _cif = cif ? cif?.toLowerCase() : "";
    const _name = name ? name?.toLowerCase() : "";
    const _cat = cat ? cat?.toLowerCase() : "";
    const _razonsocial = razonsocial ? razonsocial?.toLowerCase() : "";
    const _denomcomercial = denomcomercial ? denomcomercial?.toLowerCase() : "";
    const _owner = owner ? owner?.toLowerCase() : "";
    const _mailcontacto = mailcontacto ? mailcontacto?.toLowerCase() : "";
    const _estadoproceso = estadoproceso ? estadoproceso?.toLowerCase() : "";
    const _estadoproveedor = estadoproveedor ? estadoproveedor?.toLowerCase() : "";
    const _time = time;
    
    const filtcif = procesos.filter(
      (proc) => ((proc.cifnif?.toLowerCase().startsWith(_cif)) 
      && (proc.vendType?.toLowerCase().startsWith(_cat))
      && (proc.razonSocial?.toLowerCase().startsWith(_razonsocial))
      && (proc.denomComercial?.toLowerCase().startsWith(_denomcomercial))
      && (proc.correoOwner?.toLowerCase().startsWith(_owner))
      && (proc.mailContacto?.toLowerCase().startsWith(_mailcontacto))
      && (proc.estado?.toLowerCase().startsWith(_estadoproceso))
      && (proc.estadoProveedor?.toLowerCase().startsWith(_estadoproveedor))
      && (proc.nombreProveedor?.toLowerCase().startsWith(_name))
      && (_time !== null))
    );
    
    if (_cif === "" && _name === "" && _cat === ""
    && _razonsocial === "" && _denomcomercial === "" && _owner === ""
    && _mailcontacto === "" && _estadoproceso === "" && _estadoproveedor === ""
    ) {
      setProcesosFiltrados(procesos);
    } else {
      setProcesosFiltrados(filtcif);
    }
  }

  
  const msg = store.getState().message;
  // console.log(msg);

   return loading ? (
     <Loading />
   ) : (
    <div className="pageContainer">
      <Header />
      {msg.message === "Usuario modificado correctamente" ? (<div className="alert alert-success" role = "alert">Usuario modificado correctamente</div>) 
        : msg.message === "Petición correcta Bloquear" ? (<div className="alert alert-success" role = "alert">Proceso bloqueado correctamente</div>) 
        : msg.message === "Petición correcta Cancelar sin Deshomologar" ? (<div className="alert alert-success" role = "alert">Proceso cancelado correctamente</div>) 
        : msg.message === "Petición correcta Desbloquear" ? (<div className="alert alert-success" role = "alert">Proceso actualizado correctamente</div>) 
        : msg.message === "Petición correcta Cancelar" ? (<div className="alert alert-success" role = "alert">Proceso cancelado correctamente y revocado el acceso al proveedor</div>) 
        : (<div></div>)}
      <h2>Procesos activos de homologación</h2>
      <div className="FilterProcess">
      <Form>
          <Form.Group>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Nombre Proveedor"
                  onChange={(e) => {
                    setformMantenerFiltros({
                      cif: formMantenerFiltros.cif, 
                      name: e.target.value,
                      cat: formMantenerFiltros.cat,
                      razonsocial: formMantenerFiltros.razonsocial,
                      denomcomercial: formMantenerFiltros.denomcomercial,
                      owner: formMantenerFiltros.owner,
                      mailcontacto: formMantenerFiltros.mailcontacto,
                      estadoproceso: formMantenerFiltros.estadoproceso,
                      estadoproveedor: formMantenerFiltros.estadoproveedor,
                    })
                    filterProcesosCIF(formMantenerFiltros.cif,e.target.value,formMantenerFiltros.cat,
                      formMantenerFiltros.razonsocial,formMantenerFiltros.denomcomercial,formMantenerFiltros.owner,
                      formMantenerFiltros.mailcontacto,formMantenerFiltros.estadoproceso,formMantenerFiltros.estadoproveedor);
                  }}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="CIF/NIF"
                  onChange={(e) => {
                    setformMantenerFiltros({
                      cif: e.target.value, 
                      name: formMantenerFiltros.name,
                      cat: formMantenerFiltros.cat,
                      razonsocial: formMantenerFiltros.razonsocial,
                      denomcomercial: formMantenerFiltros.denomcomercial,
                      owner: formMantenerFiltros.owner,
                      mailcontacto: formMantenerFiltros.mailcontacto,
                      estadoproceso: formMantenerFiltros.estadoproceso,
                      estadoproveedor: formMantenerFiltros.estadoproveedor,
                    })
                    filterProcesosCIF(e.target.value,formMantenerFiltros.name,formMantenerFiltros.cat,
                      formMantenerFiltros.razonsocial,formMantenerFiltros.denomcomercial,formMantenerFiltros.owner,
                      formMantenerFiltros.mailcontacto,formMantenerFiltros.estadoproceso,formMantenerFiltros.estadoproveedor);
                  }}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Categoría"
                  onChange={(e) => {
                    setformMantenerFiltros({
                      cif: formMantenerFiltros.cif, 
                      name: formMantenerFiltros.name,
                      cat: e.target.value,
                      razonsocial: formMantenerFiltros.razonsocial,
                      denomcomercial: formMantenerFiltros.denomcomercial,
                      owner: formMantenerFiltros.owner,
                      mailcontacto: formMantenerFiltros.mailcontacto,
                      estadoproceso: formMantenerFiltros.estadoproceso,
                      estadoproveedor: formMantenerFiltros.estadoproveedor,
                    })
                    filterProcesosCIF(formMantenerFiltros.cif,formMantenerFiltros.name,e.target.value,
                      formMantenerFiltros.razonsocial,formMantenerFiltros.denomcomercial,formMantenerFiltros.owner,
                      formMantenerFiltros.mailcontacto,formMantenerFiltros.estadoproceso,formMantenerFiltros.estadoproveedor);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
              <Form.Control
                  type="text"
                  placeholder="Razón Social"
                  onChange={(e) => {
                    setformMantenerFiltros({
                      cif: formMantenerFiltros.cif, 
                      name: formMantenerFiltros.name,
                      cat: formMantenerFiltros.cat,
                      razonsocial: e.target.value,
                      denomcomercial: formMantenerFiltros.denomcomercial,
                      owner: formMantenerFiltros.owner,
                      mailcontacto: formMantenerFiltros.mailcontacto,
                      estadoproceso: formMantenerFiltros.estadoproceso,
                      estadoproveedor: formMantenerFiltros.estadoproveedor,
                    })
                    filterProcesosCIF(formMantenerFiltros.cif,formMantenerFiltros.name,formMantenerFiltros.cat,
                      e.target.value,formMantenerFiltros.denomcomercial,formMantenerFiltros.owner,
                      formMantenerFiltros.mailcontacto,formMantenerFiltros.estadoproceso,formMantenerFiltros.estadoproveedor);
                  }}
                />
              </Col>
              <Col>
              <Form.Control
                  type="text"
                  placeholder="Denom. Comercial"
                  onChange={(e) => {
                    setformMantenerFiltros({
                      cif: formMantenerFiltros.cif, 
                      name: formMantenerFiltros.name,
                      cat: formMantenerFiltros.cat,
                      razonsocial: formMantenerFiltros.razonsocial,
                      denomcomercial: e.target.value,
                      owner: formMantenerFiltros.owner,
                      mailcontacto: formMantenerFiltros.mailcontacto,
                      estadoproceso: formMantenerFiltros.estadoproceso,
                      estadoproveedor: formMantenerFiltros.estadoproveedor,
                    })
                    filterProcesosCIF(formMantenerFiltros.cif,formMantenerFiltros.name,formMantenerFiltros.cat,
                      formMantenerFiltros.razonsocial,e.target.value,formMantenerFiltros.owner,
                      formMantenerFiltros.mailcontacto,formMantenerFiltros.estadoproceso,formMantenerFiltros.estadoproveedor);
                  }}
                />
              </Col>
              <Col>
              <Form.Control
                  type="text"
                  placeholder="Owner"
                  onChange={(e) => {
                    setformMantenerFiltros({
                      cif: formMantenerFiltros.cif, 
                      name: formMantenerFiltros.name,
                      cat: formMantenerFiltros.cat,
                      razonsocial: formMantenerFiltros.razonsocial,
                      denomcomercial: formMantenerFiltros.denomcomercial,
                      owner: e.target.value,
                      mailcontacto: formMantenerFiltros.mailcontacto,
                      estadoproceso: formMantenerFiltros.estadoproceso,
                      estadoproveedor: formMantenerFiltros.estadoproveedor,
                    })
                    filterProcesosCIF(formMantenerFiltros.cif,formMantenerFiltros.name,formMantenerFiltros.cat,
                      formMantenerFiltros.razonsocial,formMantenerFiltros.denomcomercial,e.target.value,
                      formMantenerFiltros.mailcontacto,formMantenerFiltros.estadoproceso,formMantenerFiltros.estadoproveedor);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
              <Form.Control
                  type="text"
                  placeholder="Mail Contacto"
                  onChange={(e) => {
                    setformMantenerFiltros({
                      cif: formMantenerFiltros.cif, 
                      name: formMantenerFiltros.name,
                      cat: formMantenerFiltros.cat,
                      razonsocial: formMantenerFiltros.razonsocial,
                      denomcomercial: formMantenerFiltros.denomcomercial,
                      owner: formMantenerFiltros.owner,
                      mailcontacto: e.target.value,
                      estadoproceso: formMantenerFiltros.estadoproceso,
                      estadoproveedor: formMantenerFiltros.estadoproveedor,
                    })
                    filterProcesosCIF(formMantenerFiltros.cif,formMantenerFiltros.name,formMantenerFiltros.cat,
                      formMantenerFiltros.razonsocial,formMantenerFiltros.denomcomercial,formMantenerFiltros.owner,
                      e.target.value,formMantenerFiltros.estadoproceso,formMantenerFiltros.estadoproveedor);
                  }}
                />
              </Col>
              <Col>
              <Form.Control
                  type="text"
                  placeholder="Estado Proceso"
                  onChange={(e) => {
                    setformMantenerFiltros({
                      cif: formMantenerFiltros.cif, 
                      name: formMantenerFiltros.name,
                      cat: formMantenerFiltros.cat,
                      razonsocial: formMantenerFiltros.razonsocial,
                      denomcomercial: formMantenerFiltros.denomcomercial,
                      owner: formMantenerFiltros.owner,
                      mailcontacto: formMantenerFiltros.mailcontacto,
                      estadoproceso: e.target.value,
                      estadoproveedor: formMantenerFiltros.estadoproveedor,
                    })
                    filterProcesosCIF(formMantenerFiltros.cif,formMantenerFiltros.name,formMantenerFiltros.cat,
                      formMantenerFiltros.razonsocial,formMantenerFiltros.denomcomercial,formMantenerFiltros.owner,
                      formMantenerFiltros.mailcontacto,e.target.value,formMantenerFiltros.estadoproveedor);
                  }}
                />
              </Col>
              <Col>
              <Form.Control
                  type="text"
                  placeholder="Estado Proveedor"
                  onChange={(e) => {
                    setformMantenerFiltros({
                      cif: formMantenerFiltros.cif, 
                      name: formMantenerFiltros.name,
                      cat: formMantenerFiltros.cat,
                      razonsocial: formMantenerFiltros.razonsocial,
                      denomcomercial: formMantenerFiltros.denomcomercial,
                      owner: formMantenerFiltros.owner,
                      mailcontacto: formMantenerFiltros.mailcontacto,
                      estadoproceso: formMantenerFiltros.estadoproceso,
                      estadoproveedor: e.target.value,
                    })
                    filterProcesosCIF(formMantenerFiltros.cif,formMantenerFiltros.name,formMantenerFiltros.cat,
                    formMantenerFiltros.razonsocial,formMantenerFiltros.denomcomercial,formMantenerFiltros.owner,
                    formMantenerFiltros.mailcontacto,formMantenerFiltros.estadoproceso,e.target.value);
                  }}
                />
              </Col>
            </Row>
            </Form.Group>
        </Form>
      </div>
      <Table className="styled-table" responsive>
        <thead>
          <tr>
          <th>#</th>
          <th>Proveedor</th>
          <th>Tipo_Homologacion</th>
          <th>Válido_Hasta</th>
          <th>Estado Proceso</th>
          <th>CIF/NIF</th>
          <th>Razón Social</th>
          <th>Denom. Comercial</th>
          <th>Categoría Proveedor</th>
          <th>Estado Proveedor</th>
          <th>Owner</th>
          <th>Mail Contacto</th>
          <th> {">"} </th>
          </tr>
        </thead>
        <tbody>
          {procesosFiltrados?.map((proc, index) => (
            <tr
              className="clickable"
              key={proc.idProceso}
              onClick={() =>
                clickOnProcess(
                  proc.nombreProveedor,
                  proc.idProceso,
                  proc.estado
                )
              }
            >
              <td>{index + 1}</td>
              <td>{proc.nombreProveedor}</td>
              <td>{proc.tipoProceso}</td>
              <td>{proc.fecProxRenovacion.substring(0, 10)}</td>
              <td>{proc.estado}</td>
              <td>{proc.cifnif}</td>
              <td>{proc.razonSocial}</td>
              <td>{proc.denomComercial}</td>
              <td>{proc.vendType}</td>
              <td>{proc.estadoProveedor}</td>
              <td>{proc.correoOwner}</td>
              <td>{proc.mailContacto}</td>
              <td>{">"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Proceso bloqueado
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Su proceso ha sido bloqueado por haber excedido el tiempo para
          adjuntar la documentación o por decisión de los administradores. 
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn-create-form"
            onClick={CancelarProceso}
          >
            Cancelar Proceso
          </Button>
        </Modal.Footer>
        <Modal.Footer>
          <Button variant="success" onClick={DesbloquearProceso}>
            Desbloquear Proceso
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal centered show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Proceso cancelado
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>          
          Este proceso ha sido cancelado por un administrador.
        </Modal.Body>
        <Modal.Body>
          Por favor, pongase en contacto con un administrador en el correo compliance@redwoodcorp.com para determinar si 
          su proceso está correctamente cancelado.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn-create-form"
            onClick={handleClose2}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

export default connect(mapStateToProps)(AdminHome);
