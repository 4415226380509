import {
  CREATE_VENDOR_SUCCESS,
  CREATE_VENDOR_FAILURE,
  ALL_VENDOR_SUCCESS,
  ALL_VENDOR_FAILURE,
} from "../actions/types";

const initialState = {
  vendor: [],
};


export default function vendor(vendors = initialState, action) {
  switch (action.type) {
    case ALL_VENDOR_SUCCESS:
      return {
        ...vendors,
        vendor: action.payload,
      };
    case ALL_VENDOR_FAILURE:
      return {
        ...vendors,
      };
    case CREATE_VENDOR_SUCCESS:
      return {
        ...vendors,
      };
    case CREATE_VENDOR_FAILURE:
      return {
        ...vendors,
        vendor: [],
      };
    default:
      return vendors;
  }
}
