export const urlFilesHost = "https://redwoodam.sharepoint.com/sites/compliance/App/";
export const urlServer = "https://redwoodcompliance.azurewebsites.net/api";
  

export const ROLES = { ADMIN: "admin", USER: "user", VENDOR: "vendor" };

export const days = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];
export const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const monthsKey = {
  Enero: "01",
  Febrero: "02",
  Marzo: "03",
  Abril: "04",
  Mayo: "05",
  Junio: "06",
  Julio: "07",
  Agosto: "08",
  Septiembre: "09",
  Octubre: "10",
  Noviembre: "11",
  Diciembre: "12",
};

export const currentYear = new Date().getFullYear();
export const years = [
  currentYear,
  currentYear + 1,
  currentYear + 2,
  currentYear + 3,
  currentYear + 4,
];
