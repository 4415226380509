// REACT
import React, { Component } from "react";
import { connect } from "react-redux";
// LIBRARIES
import { Redirect } from "react-router-dom";
import { Button } from "react-bootstrap";
// COMPONENTS
import redwood_dark from "../images/Redwood_dark.png";
import { login } from "../actions/auth";

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      loading: true,
    });

    const { dispatch, history } = this.props;

    dispatch(login(this.state.username, this.state.password))
      .then(() => {
        history.push("/");
        window.location.reload();
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { isLoggedIn, message } = this.props;

    if (isLoggedIn) {
      return <Redirect to="/profile" />;
    }

    return (
      <div className="bodyContainer">
        <div className="login-form">
          <img src={redwood_dark} alt="" height="125" />
          <form onSubmit={this.handleLogin}>
            <h2 className="text-center">Log in</h2>
            <div className="form_input">
              <input
                type="text"
                className="form-control"
                placeholder="Username"
                required="required"
                value={this.state.username}
                onChange={this.onChangeUsername}
              />
            </div>
            <div className="form_input">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                required="required"
                value={this.state.password}
                onChange={this.onChangePassword}
              />
            </div>
            <div className="form-group col text-center">
              <Button
                type="submit"
                className="btn btn-secondary btn-block btn-login"
              >
                Sign in
              </Button>
              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default connect(mapStateToProps)(Login);
