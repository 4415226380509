import * as type from "./types";
import EventBus from "../common/EventBus";
import UserService from "../services/user.service";
import { history } from "../helpers/history";

export const sendVendorInfo = (form) => async (dispatch) => {
  return UserService.sendVendorInfo(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
      dispatch({
        type: type.SET_PRIMER_LOGIN,
        payload: false,
      });
      history.push("/");
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: type.SET_MESSAGE,
        payload: message,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};
export const editVendorInfo = (form) => async (dispatch) => {
  return UserService.editVendorInfo(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
      dispatch({
        type: type.SET_PRIMER_LOGIN,
        payload: false,
      });
      history.push("/");
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: type.SET_MESSAGE,
        payload: message,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};
