import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { Redirect } from "react-router-dom";

import store from "../store";
import { Form, Button, ToggleButton, Modal, Row, Col } from "react-bootstrap";
import Header from "./Header/Header";
import Loading from "./loading";

import ProteccionDeDatosPDF from "../images/GRC_Protecciondedatos.pdf";
import SolicitudHomologacionPDF from "../images/GRC_SolicitudHomologacion_herramienta.pdf";
import { sendVendorInfo } from "../actions/info";
// import { logout } from "../actions/auth";
import { modifyUser } from "../actions/user";
import { getVendorByUserID } from "../actions/vendor";
import { history } from "../helpers/history";

const Profile = () => {
  const currentUser = store.getState().auth.user;
  const isVendor = store.getState().auth.user?.role === "vendor";
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(true);
  const [checkedProv, setCheckedProv] = useState(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [blnComercializadora, setblnComercializadora] = useState(true);
  const [blnOtros, setblnOtros] = useState(true);
  const [blnDatosPers, setblnDatosPers] = useState(true);

  const user = store.getState().auth.user?.username;
  const role = store.getState().auth.user?.role;
  const id = store.getState().auth.user?.id;

  const [formDataProfile, setformDataProfile] = useState({
    id: id,
    username: user,
    password: "",
    repeatpassword: "",
    role: role,
  });

  const [formDataProv, setformDataProv] = useState({
    userID: "",

    RazonSocial: "",
    CIFNIF: "",
    DenComercial: "",
    IBAN: "",
    Domicilio: "",
    Localidad: "",
    Provincia: "",
    CP: "",
    Telefono: "",
    Mail: "",
    PaginaWeb: "",

    //TipoServicio: "",
    Categoria: "",
    ZonaTrabajo: "",
    Otros: "",
    Datospersonales: "",
    Detalle: "",

    Nombre_contacto: "",
    Apellido1_contacto: "",
    Apellido2_contacto: "",
    Mail_contacto: "",
    Telefono_contacto: "",

    Nombre_firmante: "",
    DatosRepresentacion_firmante: "",
    Apellido1_firmante: "",
    Apellido2_firmante: "",
    Mail_firmante: "",
    Telefono_firmante: "",
    CorreoProveedor: "",

    ProteccionDatos: false,
  });
  const [formGDPR_A, setformGDPR_A] = useState({
    Q1: "",
    Q2: "",
    Q3: "",
    Q4: "",
    Q5: "",
    Q6: "",
    Q7: "",
    Q8: "",
    Q9: "",
    Q10: "",
  });
  const [formGDPR_B, setformGDPR_B] = useState({
    Q1: "",
    Q2: "",
    Q3: "",
    Q4: "",
    Q5: "",
    Q6: "",
    Q7: "",
    Q8: "",
    Q9: "",
    Q10: "",
    Q11: "",
    Q12: "",
    Q13: "",
    Q14: "",
    Q15: "",
    Q16: "",
  });  

  const vend = store.getState().vendor?.vendor[0];

  useEffect(() => {
    if (isVendor) {
      dispatch(getVendorByUserID({ id: id }));
    }
  }, [dispatch, id, isVendor]);

  useEffect(() => {
    function updateEditForm() {
      if (vend?.accesoDatosPersonales === "Si") {
        setblnDatosPers(false);
      } else {
        setblnDatosPers(true);
      }
      
      if (vend?.categoriaProveedor === "Broker") {
        setblnComercializadora(false);
        setblnOtros(true);
        setformGDPR_A({
          Q1: vend?.RGDPQ1 === undefined ? "" : vend?.RGDPQ1,
          Q2: vend?.RGDPQ2 === undefined ? "" : vend?.RGDPQ2,
          Q3: vend?.RGDPQ3 === undefined ? "" : vend?.RGDPQ3,
          Q4: vend?.RGDPQ4 === undefined ? "" : vend?.RGDPQ4,
          Q5: vend?.RGDPQ5 === undefined ? "" : vend?.RGDPQ5,
          Q6: vend?.RGDPQ6 === undefined ? "" : vend?.RGDPQ6,
          Q7: vend?.RGDPQ7 === undefined ? "" : vend?.RGDPQ7,
          Q8: vend?.RGDPQ8 === undefined ? "" : vend?.RGDPQ8,
          Q9: vend?.RGDPQ9 === undefined ? "" : vend?.RGDPQ9,
          Q10: vend?.RGDPQ10 === undefined ? "" : vend?.RGDPQ10,
        });
      } else if (vend?.categoriaProveedor === "Otros servicios") {
        setblnOtros(false);
        setblnComercializadora(true);
        setformGDPR_B({
          Q1: vend?.RGDPQ1 === undefined ? "" : vend?.RGDPQ1,
          Q2: vend?.RGDPQ2 === undefined ? "" : vend?.RGDPQ2,
          Q3: vend?.RGDPQ3 === undefined ? "" : vend?.RGDPQ3,
          Q4: vend?.RGDPQ4 === undefined ? "" : vend?.RGDPQ4,
          Q5: vend?.RGDPQ5 === undefined ? "" : vend?.RGDPQ5,
          Q6: vend?.RGDPQ6 === undefined ? "" : vend?.RGDPQ6,
          Q7: vend?.RGDPQ7 === undefined ? "" : vend?.RGDPQ7,
          Q8: vend?.RGDPQ8 === undefined ? "" : vend?.RGDPQ8,
          Q9: vend?.RGDPQ9 === undefined ? "" : vend?.RGDPQ9,
          Q10: vend?.RGDPQ10 === undefined ? "" : vend?.RGDPQ10,
          Q11: vend?.RGDPQ11 === undefined ? "" : vend?.RGDPQ11,
          Q12: vend?.RGDPQ12 === undefined ? "" : vend?.RGDPQ12,
          Q13: vend?.RGDPQ13 === undefined ? "" : vend?.RGDPQ13,
          Q14: vend?.RGDPQ14 === undefined ? "" : vend?.RGDPQ14,
          Q15: vend?.RGDPQ15 === undefined ? "" : vend?.RGDPQ15,
          Q16: vend?.RGDPQ16 === undefined ? "" : vend?.RGDPQ16,
        });
      } else {
        setblnComercializadora(true);
        setblnOtros(true);
        setformGDPR_B({
          Q1: vend?.RGDPQ1 === undefined ? "" : vend?.RGDPQ1,
          Q2: vend?.RGDPQ2 === undefined ? "" : vend?.RGDPQ2,
          Q3: vend?.RGDPQ3 === undefined ? "" : vend?.RGDPQ3,
          Q4: vend?.RGDPQ4 === undefined ? "" : vend?.RGDPQ4,
          Q5: vend?.RGDPQ5 === undefined ? "" : vend?.RGDPQ5,
          Q6: vend?.RGDPQ6 === undefined ? "" : vend?.RGDPQ6,
          Q7: vend?.RGDPQ7 === undefined ? "" : vend?.RGDPQ7,
          Q8: vend?.RGDPQ8 === undefined ? "" : vend?.RGDPQ8,
          Q9: vend?.RGDPQ9 === undefined ? "" : vend?.RGDPQ9,
          Q10: vend?.RGDPQ10 === undefined ? "" : vend?.RGDPQ10,
          Q11: vend?.RGDPQ11 === undefined ? "" : vend?.RGDPQ11,
          Q12: vend?.RGDPQ12 === undefined ? "" : vend?.RGDPQ12,
          Q13: vend?.RGDPQ13 === undefined ? "" : vend?.RGDPQ13,
          Q14: vend?.RGDPQ14 === undefined ? "" : vend?.RGDPQ14,
          Q15: vend?.RGDPQ15 === undefined ? "" : vend?.RGDPQ15,
          Q16: vend?.RGDPQ16 === undefined ? "" : vend?.RGDPQ16,
        });
      }
      setformDataProv({
        userID: vend?.idUsuario,
        RazonSocial: vend?.razonSocial,
        CIFNIF: vend?.CIFNIF,
        DenComercial: vend?.denomComercial,
        IBAN: vend?.IBAN,
        Domicilio: vend?.domicilio,
        Localidad: vend?.localidad,
        Provincia: vend?.provincia,
        CP: vend?.CP,
        Telefono: vend?.telefonoEmpresa,
        //Mail: vend?.mailEmpresa,
        PaginaWeb: vend?.paginaWeb,
        Categoria: vend?.categoriaProveedor,

        //TipoServicio: vend?.tipoServicio,
        ZonaTrabajo: vend?.zonaTrabajo,
        Otros: vend?.otros,
        Datospersonales: vend?.accesoDatosPersonales,
        Detalle: vend?.detalleAccesoDatPers,

        Nombre_contacto: vend?.nombreContacto,
        Apellido1_contacto: vend?.apellido1Contacto,
        Apellido2_contacto: vend?.apellido2Contacto,
        Mail_contacto: vend?.mailContacto,
        Telefono_contacto: vend?.telefonoContacto,

        Nombre_firmante: vend?.nombreFirmante,
        DatosRepresentacion_firmante: vend?.datosDeRepresentacion,
        Apellido1_firmante: vend?.apellido1Firmante,
        Apellido2_firmante: vend?.apellido2Firmante,
        Mail_firmante: vend?.mailFirmante,
        Telefono_firmante: vend?.telefonoFirmante,
        CorreoProveedor: vend?.CorreoProveedor,

        ProteccionDatos: true,
      });
    }
    updateEditForm();
  }, [vend]);

  const [disabled, setDisabled] = useState({
    disabled: true,
  });

  const [disabledProv, setDisabledProv] = useState({
    disabledProv: true,
  });

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  function handleSubmit(e) {
    e.preventDefault();

    const formSubmit = formDataProfile;

    if (formDataProfile.password === formDataProfile.repeatpassword) {
      if (formDataProfile.password === "") {
        delete formSubmit.password;
        dispatch(modifyUser(formSubmit));
      } else {
        dispatch(modifyUser(formSubmit));
      }
      // dispatch(logout());
    } else {
      handleShow();
    }
  }

  function handleSubmitProv(e) {
    e.preventDefault();
    let form = {};
  if (formDataProv.Categoria === "Broker") {
      form = { ...formDataProv, ...formGDPR_A };
    } else {
      form = { ...formDataProv, ...formGDPR_B };
    }
    dispatch(sendVendorInfo(form));
  }

  function handleSubmitCancel() {
    history.push("/");
  }
  

  const loading = store.getState().loading;
  const msg = store.getState().message?.message;

  return loading ? (
    <Loading />
  ) : (
    <div className="pageContainer">
      <Header />
      {msg === 'KO' ? (<div className="alert alert-danger" role = "alert">ERROR: No se ha podido cambiar el nombre de la cuenta porque ya existe una cuenta con ese nombre.</div>) : (<div></div>)}
      <div className="formContainer">
        <div className="formHeader">
          <h2 className="text-center">Perfil de usuario</h2>
          <div>
            <ToggleButton
              className="mb-2"
              id="toggle-check"
              type="checkbox"
              variant="outline-danger"
              checked={checked}
              onChange={(e) => {
                setDisabled({ disabled: !disabled.disabled });
                setChecked(e.currentTarget.checked);
              }}
            >
              Editar
            </ToggleButton>
          </div>
        </div>

        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Nombre Usuario</Form.Label>
            <Form.Control
              disabled={disabled.disabled}
              type="text"
              placeholder="Introducir Nombre"
              value={formDataProfile.username}
              onChange={(e) =>
                setformDataProfile({
                  ...formDataProfile,
                  username: e.target.value,
                })
              }
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              disabled={disabled.disabled}
              type="password"
              placeholder="Contraseña"
              value={formDataProfile.password}
              onChange={(e) =>
                setformDataProfile({
                  ...formDataProfile,
                  password: e.target.value,
                })
              }
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword2">
            <Form.Label>Repetir Contraseña</Form.Label>
            <Form.Control
              disabled={disabled.disabled}
              type="password"
              placeholder="Repetir Contraseña"
              value={formDataProfile.repeatpassword}
              onChange={(e) =>
                setformDataProfile({
                  ...formDataProfile,
                  repeatpassword: e.target.value,
                })
              }
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <div
              style={{
                display: !isVendor ? "block" : "none",
              }}
            >
              {/* <Form.Label display={false}>Permisos de Usuario</Form.Label> */}
              <Form.Label display="false">Permisos de Usuario</Form.Label>
              <Form.Control
                disabled
                type="text"
                placeholder="Introducir Rol"
                required="required"
                value={formDataProfile.role}
                onChange={(e) =>
                  setformDataProfile({
                    ...formDataProfile,
                    mail: e.target.value,
                  })
                }
              />
            </div>
            <Form.Text className="text-muted">
              Modificar tu perfil provocará que tengas que volver a realizar el
              proceso de autenticación
            </Form.Text>
          </Form.Group>

          <Button
            disabled={disabled.disabled}
            variant="secondary"
            type="submit"
            className="btn-create-form"
          >
            Confirmar
          </Button>
        </Form>

        <Form onSubmit={handleSubmitCancel}>
            <Button variant="secondary" type="submit" className="btn-create-form">
            Cancelar
          </Button>
        </Form>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Error al editar el perfil</Modal.Title>
          </Modal.Header>
          <Modal.Body>Las contraseñas introducidas no coinciden</Modal.Body>
          <Modal.Footer>
            {/* {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button> */}
          </Modal.Footer>
        </Modal>
      </div>
      {isVendor ? (
        <div className="formContainer">
          <div className="formHeader">
            <h2 className="text-center">Perfil de Proveedor</h2>
            <div>
              <ToggleButton
                className="mb-2"
                id="toggle-checkProv"
                type="checkbox"
                variant="outline-danger"
                checked={checkedProv}
                onChange={(e) => {
                  setDisabledProv({ disabledProv: !disabledProv.disabledProv });
                  setCheckedProv(e.currentTarget.checked);
                }}
              >
                Editar
              </ToggleButton>
            </div>
          </div>
          <Form onSubmit={handleSubmitProv} className="vendorForm">
            <h5>Datos de la Empresa</h5>
            <Form.Group className="mb-3">
              <Row>
                <Col>
                  <Form.Label>Razón Social</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Razón Social (incluyendo titularidad jurídica)"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.RazonSocial}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        RazonSocial: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
                <Col>
                  <Form.Label>CIF/NIF</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="CIF/NIF"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.CIFNIF}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        CIFNIF: e.target.value.slice(0,10),
                      })
                    }
                  />
                </Col>
                <Col>
                  <Form.Label>Denominación Comercial</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Denominación Comercial"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.DenComercial}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        DenComercial: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
              </Row>
              <Form.Label>Cuenta Bancaria (IBAN)</Form.Label>
              <Form.Control
                type="text"
                placeholder="IBAN"
                required="required"
                disabled={disabledProv.disabledProv}
                value={formDataProv.IBAN}
                onChange={(e) =>
                  setformDataProv({
                    ...formDataProv,
                    IBAN: e.target.value.slice(0,100),
                  })
                }
              />
              <Row>
                <Col>
                  <Form.Label>Domicilio</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Domicilio (calle, número, portal...)"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.Domicilio}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        Domicilio: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
                <Col>
                  <Form.Label>Localidad</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Localidad"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.Localidad}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        Localidad: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Provincia</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Provincia"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.Provincia}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        Provincia: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
                <Col>
                  <Form.Label>Código Postal</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Código Postal"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.CP}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        CP: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
                <Col>
                  <Form.Label>Teléfono</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Teléfono contacto"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.Telefono}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        Telefono: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Página web</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Página web"
                    //required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.PaginaWeb}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        PaginaWeb: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
              </Row>
            </Form.Group>

            <h5>Datos del Servicio</h5>
            <Form.Group className="mb-3">
                <Form.Group hidden={blnComercializadora}>
                <Form.Label>Zona de trabajo</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Zona de Trabajo"
                  required={!blnComercializadora}
                  disabled={disabledProv.disabledProv}
                  value={formDataProv.ZonaTrabajo}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      ZonaTrabajo: e.target.value.slice(0,100),
                    })
                  }
                />
              </Form.Group>
              <Form.Group hidden={blnOtros}>
                <Form.Label>Otros</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Tipo de servicio"
                  required={!blnOtros}
                  disabled={disabledProv.disabledProv}
                  value={formDataProv.Otros}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Otros: e.target.value.slice(0,100),
                    })
                  }
                />
              </Form.Group>
              <Form.Label>Acceso a datos personales</Form.Label>
              <Form.Select
                required="required"
                disabled={disabledProv.disabledProv}
                value={formDataProv.Datospersonales}
                onChange={(e) => {
                  setformDataProv({
                    ...formDataProv,
                    Datospersonales: e.target.value,
                  });

                  if (e.target.value === "Si") {
                    setblnDatosPers(false);
                  } else {
                    setblnDatosPers(true);
                  }
                }}
              >
                <option value="" hidden>
                  ---
                </option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Form.Select>
              <Form.Group hidden={blnDatosPers}>
                <Form.Label>Detalle datos personales</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Especificar qué datos personales trata: nombre, DNI, datos de contacto, situación laboral, etc..."
                  required={!blnDatosPers}
                  disabled={disabledProv.disabledProv}
                  value={formDataProv.Detalle}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Detalle: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Form.Group>
            <h5>Datos de contacto</h5>
            <Form.Group className="mb-3">
              <Row>
                <Col>
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nombre"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.Nombre_contacto}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        Nombre_contacto: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
                <Col>
                  <Form.Label>Apellido</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Primer Apellido"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.Apellido1_contacto}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        Apellido1_contacto: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
                <Col>
                  <Form.Label>Apellido 2</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Segundo Apellido"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.Apellido2_contacto}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        Apellido2_contacto: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Correo Proveedor</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Dirección de correo"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.Mail_contacto}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        Mail_contacto: e.target.value,
                        CorreoProveedor: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
                <Col>
                  <Form.Label>Teléfono</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Teléfono de Contacto"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.Telefono_contacto}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        Telefono_contacto: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
            <h5>Datos de firmante del contrato</h5>
            <Form.Group className="mb-3">
              <Row>
                <Col>
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nombre"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.Nombre_firmante}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        Nombre_firmante: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
                <Col>
                  <Form.Label>Apellido</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Primer Apellido"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.Apellido1_firmante}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        Apellido1_firmante: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
                <Col>
                  <Form.Label>Apellido 2</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Segundo Apellido"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.Apellido2_firmante}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        Apellido2_firmante: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
              </Row>
              <Form.Label>Datos de representación</Form.Label>
              <Form.Control
                className="formDatosRep"
                type="text"
                placeholder="(cargo, notario, protocolo, fecha)"
                required="required"
                disabled={disabledProv.disabledProv}
                value={formDataProv.DatosRepresentacion_firmante}
                onChange={(e) =>
                  setformDataProv({
                    ...formDataProv,
                    DatosRepresentacion_firmante: e.target.value.slice(0,100),
                  })
                }
              />
              <Row>
                <Col>
                  <Form.Label>Correo firmante</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Dirección de correo"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.Mail_firmante}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        Mail_firmante: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
                <Col>
                  <Form.Label>Teléfono</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Teléfono"
                    required="required"
                    disabled={disabledProv.disabledProv}
                    value={formDataProv.Telefono_firmante}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        Telefono_firmante: e.target.value.slice(0,100),
                      })
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
            <h5>Reglamento General de Protección de Datos</h5>
            {!blnComercializadora ? (
              <Form.Group className="mb-3">
                <Row>
                  <b>
                    Medidas de Seguridad y Política de seguridad de la
                    Información{" "}
                  </b>
                  <Col className="textGDPR">
                    <Form.Label>
                      Confirmación e identificación de: i) los activos
                      implicados en el tratamiento de los datos personales y
                      control de los soportes de almacenamiento (incluyendo una
                      gestión de soportes extraíbles, dispositivos móviles como
                      teléfonos, pendrives, tablets, portátiles, etc.) y ii) los
                      controles de acceso a sistemas y aplicaciones y de la
                      relación actualizada de los usuarios y perfiles de
                      usuarios, y los accesos autorizados para cada uno de
                      ellos.
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={!blnComercializadora}
                      disabled={disabledProv.disabledProv}
                      value={formGDPR_A.Q1}
                      onChange={(e) => {
                        setformGDPR_A({
                          ...formGDPR_A,
                          Q1: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <Col className="textGDPR">
                    <Form.Label>
                      Confirmación de la existencia de contraseñas para el
                      acceso a los datos personales almacenados en sistemas
                      electrónicos.
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={!blnComercializadora}
                      disabled={disabledProv.disabledProv}
                      value={formGDPR_A.Q2}
                      onChange={(e) => {
                        setformGDPR_A({
                          ...formGDPR_A,
                          Q2: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <Col className="textGDPR">
                    <Form.Label>
                      Cuenta con un procedimiento de gestión de copias de
                      seguridad y recuperación de la información. Identificación
                      y periodicidad de las mismas.
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={!blnComercializadora}
                      disabled={disabledProv.disabledProv}
                      value={formGDPR_A.Q3}
                      onChange={(e) => {
                        setformGDPR_A({
                          ...formGDPR_A,
                          Q3: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <Col className="textGDPR">
                    <Form.Label>
                      "Identificación del sistema de protección ante códigos
                      maliciosos. i) Identificación del sistema de antivirus que
                      garantice en la medida posible el robo y destrucción de la
                      información y datos personales. ii) Identificación de la
                      existencia de un firewall activado en aquellos ordenadores
                      y dispositivos en los que se realice el almacenamiento y/o
                      tratamiento de datos personales que pueda identificar los
                      accesos no autorizados"
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={!blnComercializadora}
                      disabled={disabledProv.disabledProv}
                      value={formGDPR_A.Q4}
                      onChange={(e) => {
                        setformGDPR_A({
                          ...formGDPR_A,
                          Q4: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <b>Incidentes de seguridad</b>
                  <Col className="textGDPR">
                    <Form.Label>
                      Dispone de procedimientos de gestión de incidentes que
                      incluyan el reporte al responsable de tratamiento sin
                      dilación indebida, en caso de que se produzca un incidente
                      de seguridad que afecte a los datos de carácter personal.
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={!blnComercializadora}
                      disabled={disabledProv.disabledProv}
                      value={formGDPR_A.Q5}
                      onChange={(e) => {
                        setformGDPR_A({
                          ...formGDPR_A,
                          Q5: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <Col className="textGDPR">
                    <Form.Label>
                      Dentro del procedimiento de gestión de incidentes, en el
                      reporte a realizar al responsable de tratamiento, se
                      describe la naturaleza de la incidencia, el número
                      aproximado y categorías de datos, la información de
                      contacto del DPO, la descripción de las medidas propuestas
                      para remediar o mitigar la incidencia así como las
                      posibles consecuencias de dicha incidencia.
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={!blnComercializadora}
                      disabled={disabledProv.disabledProv}
                      value={formGDPR_A.Q6}
                      onChange={(e) => {
                        setformGDPR_A({
                          ...formGDPR_A,
                          Q6: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <b>Empleados</b>
                  <Col className="textGDPR">
                    <Form.Label>
                      Los empleados que van a acceder a los datos personales del
                      cliente firman las pertinentes cláusulas de
                      confidencialidad.
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={!blnComercializadora}
                      disabled={disabledProv.disabledProv}
                      value={formGDPR_A.Q7}
                      onChange={(e) => {
                        setformGDPR_A({
                          ...formGDPR_A,
                          Q7: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <b>Subcontrataciones</b>
                  <Col className="textGDPR">
                    <Form.Label>
                      En el caso que el servicio lleve aparejadas
                      subcontrataciones de terceros, es decir, el Broker cuente
                      con terceras empresas en las que delega el tratamiento de
                      datos personales responsabilidad de Redwood ¿Se encuentra
                      regulada dicha relación desde un punto de vista de
                      protección de datos? ¿Cuenta con los respectivos acuerdos
                      en materia de protección de datos?
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={!blnComercializadora}
                      value={formGDPR_A.Q8}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_A({
                          ...formGDPR_A,
                          Q8: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <b>Transferencias internacionales de datos</b>
                  <Col className="textGDPR">
                    <Form.Label>
                      "Si con motivo de la prestación de los servicios, los
                      datos personales pueden ser accesibles desde algún país
                      que no sea alguno de los siguientes países: miembros de la
                      UE, Suiza, Canadá, Argentina, Guernsey, Isla de Man,
                      Jersey, Islas Feroe, Andorra, Israel, Uruguay y Nueva
                      Zelanda o bien se subcontratan servicios con terceros
                      cuyos servicios son prestados desde fuera de la UE.
                      ¿Cuenta con las garantías adecuadas recogidas en RGPD?
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={!blnComercializadora}
                      value={formGDPR_A.Q9}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_A({
                          ...formGDPR_A,
                          Q9: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <b>Tratamiento de datos especialmente protegidos</b>
                  <Col className="textGDPR">
                    <Form.Label>
                      ¿Trata datos de salud, antecedentes penales o cualquier
                      tipo de datos categorizados como especialmente protegidos
                      por la normativa aplicable?
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={!blnComercializadora}
                      value={formGDPR_A.Q10}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_A({
                          ...formGDPR_A,
                          Q10: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <br />
                  <Form.Check
                    required={!blnComercializadora}
                    disabled={disabledProv.disabledProv}
                    inline
                    type="checkbox"
                    name="proteccionDatos"
                    id="proteccionDatos"
                    value={formDataProv.ProteccionDatos}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        ProteccionDatos: !formDataProv.ProteccionDatos,
                      })
                    }
                  />
                  Acepto la{" "}
                  <a
                    href={SolicitudHomologacionPDF}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    solicitud de homologación{" "}
                  </a>
                  y medidas de{" "}
                  <a
                    href={ProteccionDeDatosPDF}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    protección de datos
                  </a>
                </Form.Group>
              </Form.Group>
            ) : (
              <Form.Group className="mb-3">
                <Row>
                  <b>Gobierno y control </b>
                  <Col className="textGDPR">
                    <Form.Label>
                      ¿Dispone de un DPO (Delegado de Protección de Datos) o de
                      una figura responsable de protección de datos?
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={blnComercializadora}
                      value={formGDPR_B.Q1}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_B({
                          ...formGDPR_B,
                          Q1: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <b>Cifrado de la información</b>
                  <Col className="textGDPR">
                    <Form.Label>
                      ¿Ha procedido a la identificación de las técnicas de
                      cifrado utilizadas?
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={blnComercializadora}
                      value={formGDPR_B.Q2}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_B({
                          ...formGDPR_B,
                          Q2: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <b>Certificaciones y auditorías</b>
                  <Col className="textGDPR">
                    <Form.Label>
                      ¿Dispone de informes de Auditoría recientes sobre
                      protección de datos (dos últimos años) realizadas por un
                      tercero confiable que evidencien buenas prácticas en
                      materia de protección de datos?
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={blnComercializadora}
                      value={formGDPR_B.Q3}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_B({
                          ...formGDPR_B,
                          Q3: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <b>Evaluaciones de impacto (PIAs)</b>
                  <Col className="textGDPR">
                    <Form.Label>
                      ¿Ha remitido evidencia de la/s Evaluación/es de Impacto
                      llevadas a cabo (PIAs) para el tratamiento/s de datos
                      afectado/s ?
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={blnComercializadora}
                      value={formGDPR_B.Q4}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_B({
                          ...formGDPR_B,
                          Q4: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <b>Plan de continuidad de negocio - BCP </b>
                  <Col className="textGDPR">
                    <Form.Label>
                      Cuenta con un Plan de Continuidad de negocio (BCP
                      -Business Continuity Plan)
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={blnComercializadora}
                      value={formGDPR_B.Q5}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_B({
                          ...formGDPR_B,
                          Q5: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <b>Medidas de Seguridad y Seguridad de la Información</b>
                  <Col className="textGDPR">
                    <Form.Label>
                      Dispone de una política actualizada de seguridad de la
                      información
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={blnComercializadora}
                      value={formGDPR_B.Q6}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_B({
                          ...formGDPR_B,
                          Q6: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <Col className="textGDPR">
                    <Form.Label>
                      Cuenta con una Política de seguridad de dispositivos
                      móviles
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={blnComercializadora}
                      value={formGDPR_B.Q7}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_B({
                          ...formGDPR_B,
                          Q7: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <Col className="textGDPR">
                    <Form.Label>
                      Cuenta con un procedimiento de gestión de copias de
                      seguridad y recuperación de la información
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={blnComercializadora}
                      value={formGDPR_B.Q8}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_B({
                          ...formGDPR_B,
                          Q8: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <Col className="textGDPR">
                    <Form.Label>
                      Ha procedido a la identificación del sistema de protección
                      ante códigos maliciosos
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={blnComercializadora}
                      value={formGDPR_B.Q9}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_B({
                          ...formGDPR_B,
                          Q9: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <b>Incidentes de seguridad</b>
                  <Col className="textGDPR">
                    <Form.Label>
                      Dispone de procedimientos de gestión de incidentes que
                      incluyan el reporte al responsable de tratamiento sin
                      dilación indebida, en caso de que se produzca un incidente
                      de seguridad que afecte a los datos de carácter personal.
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={blnComercializadora}
                      value={formGDPR_B.Q10}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_B({
                          ...formGDPR_B,
                          Q10: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <Col className="textGDPR">
                    <Form.Label>
                      Dentro del procedimiento de gestión de incidentes, en el
                      reporte a realizar al responsable de tratamiento, se
                      describe la naturaleza de la incidencia, el número
                      aproximado y categorías de datos, la información de
                      contacto del DPO, la descripción de las medidas propuestas
                      para remediar o mitigar la incidencia así como las
                      posibles consecuencias de dicha incidencia.
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={blnComercializadora}
                      value={formGDPR_B.Q11}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_B({
                          ...formGDPR_B,
                          Q11: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <b>Empleados</b>
                  <Col className="textGDPR">
                    <Form.Label>
                      Los empleados del tercero que vayan a acceder a los datos
                      personales del cliente firman las pertinentes cláusulas de
                      confidencialidad.
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={blnComercializadora}
                      value={formGDPR_B.Q12}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_B({
                          ...formGDPR_B,
                          Q12: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <b>Análisis de riesgos</b>
                  <Col className="textGDPR">
                    <Form.Label>
                      Ha remitido evidencia de los análisis de riesgos de los
                      tratamientos llevados a cabo
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={blnComercializadora}
                      value={formGDPR_B.Q13}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_B({
                          ...formGDPR_B,
                          Q13: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <b>Subcontrataciones</b>
                  <Col className="textGDPR">
                    <Form.Label>
                      En el caso que el servicio lleve aparejadas
                      subcontrataciones de terceros, ¿Se encuentra regulada
                      dicha relación desde un punto de vista de protección de
                      datos? ¿Cuenta con los respectivos acuerdos en materia de
                      protección de datos?
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={blnComercializadora}
                      value={formGDPR_B.Q14}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_B({
                          ...formGDPR_B,
                          Q14: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <b>Transferencias internacionales de datos</b>
                  <Col className="textGDPR">
                    <Form.Label>
                      "Si con motivo de la prestación de los servicios, los
                      datos personales pueden ser accesibles desde algún país
                      que no sea alguno de los siguientes países: miembros de la
                      UE, Suiza, Canadá, Argentina, Guernsey, Isla de Man,
                      Jersey, Islas Feroe, Andorra, Israel, Uruguay y Nueva
                      Zelanda o bien se subcontratan servicios con terceros
                      cuyos servicios son prestados desde fuera de la UE.
                      ¿Cuenta con las garantías adecuadas recogidas en RGPD?
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={blnComercializadora}
                      value={formGDPR_B.Q15}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_B({
                          ...formGDPR_B,
                          Q15: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <b>Tratamiento de datos especialmente protegidos</b>
                  <Col className="textGDPR">
                    <Form.Label>
                      ¿Trata datos de salud, antecedentes penales o cualquier
                      tipo de datos categorizados como especialmente protegidos
                      por la normativa aplicable?
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Select
                      required={blnComercializadora}
                      value={formGDPR_B.Q16}
                      disabled={disabledProv.disabledProv}
                      onChange={(e) => {
                        setformGDPR_B({
                          ...formGDPR_B,
                          Q16: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="1">Si</option>
                      <option value="0">No</option>
                      <option value="-1">N/A</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <br />
                  <Form.Check
                    required={!blnComercializadora}
                    disabled={disabledProv.disabledProv}
                    inline
                    type="checkbox"
                    name="proteccionDatos"
                    id="proteccionDatos"
                    value={formDataProv.ProteccionDatos}
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        ProteccionDatos: !formDataProv.ProteccionDatos,
                      })
                    }
                  />
                  Acepto la{" "}
                  <a
                    href={SolicitudHomologacionPDF}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    solicitud de homologación{" "}
                  </a>
                  y medidas de{" "}
                  <a
                    href={ProteccionDeDatosPDF}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    protección de datos
                  </a>
                </Form.Group>
              </Form.Group>
            )}

            <Button
              variant="secondary"
              type="submit"
              disabled={disabledProv.disabledProv}
              className="btn-create-form"
            >
              Guardar
            </Button>
          </Form>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
    isLoadingVendor: state.vendor,
  };
};

export default connect(mapStateToProps)(Profile);
