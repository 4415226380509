import {
    ALL_CATEGORYVENDOR_SUCCESS,
    ALL_CATEGORYVENDOR_FAILURE,
  } from "../actions/types";
  
  const initialState = {
    vendorcategory: [],
  };
  
  
  export default function vendorcategory(vendorcategorys = initialState, action) {
    switch (action.type) {
      case ALL_CATEGORYVENDOR_SUCCESS:
        return {
          ...vendorcategorys,
          vendorcategory: action.payload,
        };
      case ALL_CATEGORYVENDOR_FAILURE:
        return {
          ...vendorcategorys,
        };
      default:
        return vendorcategorys;
    }
  }