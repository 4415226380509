import axios from "axios";
import * as config from "../config/config";

const urlLogin = config.urlServer + "/login";

class AuthService {
  login(username, password) {
    return axios.post(urlLogin, { username, password }).then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
  }

  logout() {
    localStorage.removeItem("user");
  }
}

export default new AuthService();
