// REACT
import React from "react";
import { useDispatch } from "react-redux";
// LIBRARIES
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
// COMPONENTS
// import redwood from "../../images/Redwood_logo.png";
import redwood from "../../images/redwood_5_150ppp.png";
import { history } from "../../helpers/history";
import { logout } from "../../actions/auth";

const UserHeader = () => {
  const dispatch = useDispatch();

  const userLogOut = () => {
    dispatch(logout());
  };

  return (
    <div>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        {/* <img src={redwood_dark} alt="" width="40" /> */}
        <div className="redwoodBrand">
          <img src={redwood} alt="" width="100" />
          {/* <Navbar.Brand>Redwood</Navbar.Brand> */}
        </div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              onClick={() => {
                history.push("/");
              }}
            >
              Inicio
            </Nav.Link>
            <NavDropdown title="Proveedor">
              <NavDropdown.Item
                onClick={() => {
                  history.push("/crear");
                }}
                className="header-dropdown-prov"
              >
                Crear
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => {
                  history.push("/editar");
                }}
                className="header-dropdown-prov"
              >
                Editar
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Proceso">
              <NavDropdown.Item
                onClick={() => {
                  history.push("/asignar");
                }}
                className="header-dropdown-prov"
              >
                Asignar
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => {
                  history.push("/revisar");
                }}
                className="header-dropdown-prov"
              >
                Revisar
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="justify-content-end">
            <Nav.Link
              className="profile"
              onClick={() => {
                history.push("/user/profile");
              }}
            >
              Perfil
            </Nav.Link>
            <Nav className="justify-content-end">
              <Nav.Link className="logout" onClick={userLogOut}>
                Logout
              </Nav.Link>
            </Nav>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
export default UserHeader;
