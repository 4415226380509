// REACT
import React, { Component } from "react";
import { connect } from "react-redux";
// LIBRARIES
import { Router, Switch, Route, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
//  COMPONENTS
import "./App.css";
import Login from "./components/login";
import Home from "./components/home";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { history } from "./helpers/history";
// Logout handlers when token expires
import EventBus from "./common/EventBus";
import AuthVerify from "./common/auth-verify";
// LOGGED IN
import userProfile from "./components/profile";
import changePass from "./components/changePassword";
// ADMIN
import createUser from "./components/Admin/createUser";
import resetPass from "./components/Admin/resetPass";
import Plantilla from "./components/Admin/plantilla";
// USER
import Revisar from "./components/Proceso/revisar";
import Asignar from "./components/Proceso/asignar";
import Editar from "./components/Proveedor/editar";
import Crear from "./components/Proveedor/crear";
// VENDOR
import Proceso from "./components/User/Proceso";
import Procesos from "./components/User/Procesos";
import Info from "./components/User/Info";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };

    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }

  componentDidMount() {
    const user = this.props.user;
    if (user) {
      this.setState({
        currentUser: user,
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    this.props.dispatch(logout());
  }

  render() {
    return (
      <Router history={history}>
        {!this.props.user ? (
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route render={() => <Redirect to="/login" />} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path={["/", "/home"]} component={Home} />

            {/* LOGGED IN */}
            <Route exact path="/user/profile" component={userProfile} />
            <Route exact path="/user/changePass" component={changePass} />
            {/* ADMIN */}
            <Route exact path="/plantilla" component={Plantilla} />
            <Route exact path="/user/create" component={createUser} />
            <Route exact path="/user/reset" component={resetPass} />

            {/* USER */}
            <Route exact path="/crear" component={Crear} />
            <Route exact path="/editar" component={Editar} />
            <Route exact path="/asignar" component={Asignar} />
            <Route exact path="/revisar" component={Revisar} />
            {/* VENDOR */}
            <Route exact path="/procesos/:user/:id" component={Procesos} />
            <Route
              exact
              path="/procesos/:user/:id/doc/:doc"
              component={Proceso}
            />
            {/* <Route exact path="/documentos" component={Documentos} /> */}
            <Route exact path="/info" component={Info} />

            <Redirect exact path="/login" to="/" />
            <Redirect path="*" to="/" />
            {/* <Route path="/user" component={BoardUser} />
              <Route path="/mod" component={BoardModerator} />
              <Route path="/admin" component={BoardAdmin} /> */}
          </Switch>
        )}
        <AuthVerify logOut={this.logOut} />
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);
