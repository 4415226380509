import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../actions/auth";
import store from "../store";
import Header from "./Header/Header";
import { changeUserPass } from "../actions/user";
import { Form, Button, Modal } from "react-bootstrap";

const ChangePass = () => {
  const user = store.getState().auth.user?.username;
  const id = store.getState().auth.user?.id;
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formDataProfile, setformDataProfile] = useState({
    id: id,
    username: user,
    password: "",
    repeatpassword: "",
  });

  function handleSubmit(e) {
    e.preventDefault();

    const formSubmit = formDataProfile;

    if (formDataProfile.password === formDataProfile.repeatpassword) {
      dispatch(changeUserPass(formSubmit));
      dispatch(logout());
    } else {
      handleShow();
    }
  }

  return (
    <div className="pageContainer">
      <Header />
      <div className="formContainer">
        <h2> Es necesario modificar la contraseña de inicio de sesión</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Nueva Contraseña</Form.Label>
            <Form.Control
              type="password"
              placeholder="Contraseña"
              value={formDataProfile.password}
              onChange={(e) =>
                setformDataProfile({
                  ...formDataProfile,
                  password: e.target.value,
                })
              }
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword2">
            <Form.Label>Repetir Nueva Contraseña</Form.Label>
            <Form.Control
              type="password"
              placeholder="Repetir Contraseña"
              value={formDataProfile.repeatpassword}
              onChange={(e) =>
                setformDataProfile({
                  ...formDataProfile,
                  repeatpassword: e.target.value,
                })
              }
            />
          </Form.Group>

          <Form.Text className="text-muted">
            Modificar tu perfil provocará que tengas que volver a realizar el
            proceso de autenticación
          </Form.Text>
          <Button variant="secondary" type="submit" className="btn-create-form">
            Confirmar
          </Button>
        </Form>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Error al modificar las contraseñas</Modal.Title>
          </Modal.Header>
          <Modal.Body>Las contraseñas introducidas no coinciden.</Modal.Body>
          <Modal.Footer>
            {/* {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button> */}
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
export default ChangePass;
