// REACT
import React, { useState } from "react";
import { useDispatch,connect } from "react-redux";
// LIBRARIES
import { Form, Button } from "react-bootstrap";
// COMPONENTS
import store from "../../store";
import { createUser } from "../../actions/user";
import Loading from "../loading";
import Header from "../Header/Header";
import * as lib from "../../functions/functions";
import { generatePassword } from "../../functions/functions";
import { history } from "../../helpers/history";

const Admin = () => {
  const dispatch = useDispatch();
  const currentUser = store.getState().auth.user;
  
  const [formCreateUser, setformCreateUser] = useState({
    username: "",
    password: "",
    role: "",
    mail: "",
  });

  

  lib.userAccessbyRol(currentUser, "ADMIN");

  function handleSubmit(e) {
    e.preventDefault();
    formCreateUser.password = generatePassword();
    
    dispatch(createUser(formCreateUser));
    
  }

  function handleSubmitCancel() {
    history.push("/");
  }

  const msg = store.getState().message?.message;
  const loading = store.getState().loading;
  //console.log(msg);

  return loading ? (
    <Loading />
  ) : (
    <div className="pageContainer">
      <Header />
      {msg === 'KO' ? (<div className="alert alert-danger" role = "alert">ERROR: No se ha podido crear el usuario porque ya existe un usuario con ese nombre.</div>) : (<div></div>)}
      <div className="formContainer">
        <h2>Nuevo Acceso</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Nombre Usuario</Form.Label>
            <Form.Control
              type="text"
              placeholder="Introducir Nombre de usuario"
              required="required"
              value={formCreateUser.username}
              onChange={(e) =>
                setformCreateUser({
                  ...formCreateUser,
                  username: e.target.value,
                })
              }
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Correo Usuario</Form.Label>
            <Form.Control
              type="text"
              placeholder="Introducir Correo de usuario"
              required="required"
              value={formCreateUser.mail}
              onChange={(e) =>
                setformCreateUser({
                  ...formCreateUser,
                  mail: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicProveedor">
            <Form.Label>Permisos</Form.Label>
            <Form.Select
              aria-label="Default select example"
              required="required"
              value={formCreateUser.role}
              onChange={(e) =>
                setformCreateUser({
                  ...formCreateUser,
                  role: e.target.value,
                })
              }
            >
              <option value="" hidden disabled>
                ---
              </option>
              <option value="admin">Administrador</option>
              <option value="user">Usuario</option>
            </Form.Select>
          </Form.Group>

          <Button variant="secondary" type="submit" className="btn-create-form">
            Crear
          </Button>
        </Form>
        <Form onSubmit={handleSubmitCancel}>
        <Button variant="secondary" type="submit" className="btn-create-form">
            Cancelar
          </Button>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

export default  connect(mapStateToProps)(Admin);
