// REACT
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, connect } from "react-redux";
// LIBRARIES
import { useParams } from "react-router-dom";
import { Button, Form, CloseButton, Row, Col } from "react-bootstrap";
// COMPONENTS
import * as lib from "../../functions/functions";
import store from "../../store";
import Loading from "../loading";
import Header from "../Header/Header";
//import { getVendorByUserID } from "../../actions/vendor";
import { getDocuments } from "../../actions/documents";
import {
  uploadFileSharepoint,
  uploadNAFile,
  sendMailToComplianceAllDocUploaded,
} from "../../actions/documents";
import { history } from "../../helpers/history";

const Proceso = () => {
  const currentUser = store.getState().auth.user;
  const dispatch = useDispatch();
  const ref = useRef();
  const { user, id, doc } = useParams();

  const currentDoc = doc - 1;
  const [uploadFile, setUploadFile] = useState({
    selectedFile: null,
    test: "test1",
  });

  const [messageextension,setMessageExtension] = useState(false);

  const [formNAProv, setformNAProv] = useState({
    Comment: "",
  });

  // useEffect(() => {
  //   dispatch(getVendorByUserID(store.getState().process.process[0]?.idUsuario));
  // }, [dispatch]);
  // //const proveedor = store.getState().vendor?.vendor[0];
  
  const isFileLoading = store.getState().documents?.docLoading;
  useEffect(() => {
    dispatch(getDocuments({ userName: user, processID: id }));
  }, [dispatch, id, user]);


  lib.userAccessbyRoles(currentUser, "VENDOR", currentUser.role.toUpperCase());
  
  function allDocUploaded(doc) {
    const total = doc.length;
    let uploaded = 0;
    doc.map((doc) => {
      if (["N/A", "Adjuntado","Validado"].includes(doc.estado)) {
        uploaded += 1;
      }
      return uploaded;
    });
    if (total - uploaded < 2) {
      return true;
    } else {
      return false;
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    function reverseString(str) {
      return str.split("").reverse().join("");
    }
    
    const reversename = reverseString(uploadFile.name);
    const posicionpunto = reversename.indexOf(".");
    const posicionslice = (posicionpunto+1)*-1;
    // console.log("prueba");
    // console.log(prueba);
    // console.log(hola);
    // console.log(uploadFile.name.slice(posicionslice,uploadFile.name.length));
    // console.log(posicionpunto);
    // console.log(posicionslice);
    // console.log(uploadFile.name.slice(posicionslice,uploadFile.name.length));
    if( posicionpunto !== 0 )
    {
    const form = {
      allDocUploaded: allDocUploaded(store.getState().documents.documents),
      tipo: "subida",
      user: user,
      id: id,
      estado: "Adjuntado",
      idProceso: store.getState().process.process[0]?.idProceso,
      idDocumento:
        store.getState().documents.documents?.[currentDoc]?.idDocumento,
      idUsuario: currentUser.id,
      proveedor: store.getState().process.process[0]?.nombreProveedor,
      //extension: uploadFile.name.slice(-4,uploadFile.name.length),
      extension: uploadFile.name.slice(posicionslice,uploadFile.name.length),
      extensionant:
        store.getState().documents.documents?.[currentDoc]?.extensionDoc, 
      // tipoproceso: store.getState().process.process[0]?.tipoProceso,
      // fecini: store.getState().process.process[0]?.fecIniValidez,
    };
    
    if (allDocUploaded(store.getState().documents.documents)) {
      dispatch(sendMailToComplianceAllDocUploaded(form));
    }
    if (!isFileLoading) {
      const data = new FormData();
      data.append(
        "docName",
        store.getState().documents.documents?.[currentDoc]?.nombre
      );
      data.append("usuario", store.getState().process.process[0]?.nombreProveedor);
      data.append(
        "descripcion",
        store.getState().process.process[0]?.descripcion
      );
      data.append("file", uploadFile);
      data.append("tipoproceso", store.getState().process.process[0]?.tipoProceso);
      data.append("fecini", store.getState().process.process[0]?.fecIniValidez);
      data.append("extension", uploadFile.name.slice(posicionslice,uploadFile.name.length));
      data.append("extensionant", store.getState().documents.documents?.[currentDoc]?.extensionDoc);
      dispatch(uploadFileSharepoint(data, form));
    }
    }
    else {
      setMessageExtension(true);
    }
  }

  function handleSubmitNA(e) {
    e.preventDefault();
    const finalform = {
      allDocUploaded: allDocUploaded(store.getState().documents.documents),
      Comment: "N/A: " + formNAProv.Comment,
      user: user,
      id: id,
      idProceso: store.getState().process.process[0]?.idProceso,
      idDocumento:
        store.getState().documents.documents?.[currentDoc]?.idDocumento,
      idUsuario: currentUser.id,
      proveedor: store.getState().process.process[0]?.nombreProveedor,
    };
    if (allDocUploaded(store.getState().documents.documents)) {
      dispatch(sendMailToComplianceAllDocUploaded(finalform));
    }
    dispatch(uploadNAFile(finalform));
  }

  function backButton() {
    history.push("/procesos/" + user + "/" + id);
  }

  const loading = store.getState().loading;
  const comment =
    store.getState().documents.documents?.[currentDoc]?.comentario;

  return loading ? (
    <Loading />
  ) : (
    <div className="pageContainer">
      <Header />
      {messageextension ? (<div className="alert alert-danger" role = "alert">ERROR: Los documentos subidos tener extensión</div>) : (<div></div>)}
      <div className="formContainerUpload">
        {/* <h2>{store.getState().process.process[0]?.descripcion}</h2> */}
        <div className="backButton">
          <CloseButton onClick={backButton} />
        </div>
        {/* <ProgressBar
          variant="danger"
          now={
            (currentDoc + 1) *
            (100 / store.getState().documents.documents.length)
          }
        /> */}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicName">
            <div className="uploadFiles">
              <h4
                style={{
                  textAlign: "center",
                  textDecoration: "underline",
                }}
              >
                {store.getState().documents.documents?.[currentDoc]?.nombre}
              </h4>
              <p>
                {
                  store.getState().documents.documents?.[currentDoc]
                    ?.descripcion
                }{" "}
              </p>
              <input
                className="inputFile"
                type="file"
                required="required"
                ref={ref}
                onChange={(e) => setUploadFile(e.target.files[0])}
              />
            </div>
          </Form.Group>
          {comment ? (
            <div className="uploadFilesComment">
              <b>Comentario:</b>
              <p> {comment}</p>
            </div>
          ) : (
            <div></div>
          )}
          <div className="buttonsDocuments">
            <Button
              variant="secondary"
              type="submit"
              className="btn-create-form"
              disabled={isFileLoading}
            >
              {isFileLoading ? "Subiendo..." : "Upload File"}
            </Button>
          </div>
        </Form>
        <div className="separador">
          <Form onSubmit={handleSubmitNA}>
            <Form.Label>
              Si considera que el documento seleccionado no aplica para su
              proceso de homologación, añada un comentario y pulse el botón N/A{" "}
            </Form.Label>
            <Row>
              <Col className="colComentarioNA">
                <Form.Control
                  type="text"
                  placeholder="Comentario"
                  required="required"
                  value={formNAProv.Comment}
                  onChange={(e) =>
                    setformNAProv({
                      ...formNAProv,
                      Comment: e.target.value,
                    })
                  }
                />
              </Col>
              <Col className="colBotonNA">
                <Button
                  variant="secondary"
                  type="submit"
                  className="btn-create-form"
                >
                  N/A
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
    documents: state.documents,
  };
};

export default connect(mapStateToProps)(Proceso);
