import * as type from "./types";
import EventBus from "../common/EventBus";
import { generatePassword } from "../functions/functions";
import { history } from "../helpers/history";
import UserService from "../services/user.service";
import userService from "../services/user.service";

export const createVendor = (form) => async (dispatch) => {
  dispatch({
    type: type.START_LOADING,
  });
  const randomPass = generatePassword();
  const userForm = {
    username: form.username,
    password: randomPass,
    role: "vendor",
    vendormail: form.vendormail,
  };
  return UserService.postCreateUser(userForm).then(
    (response) => {
    form["userID"] = response.data.id;
    // Comrproabar el response.data.message y si es KO no continuamos
    if(response.data.message === "KO"){
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
      dispatch({
        type: type.CREATE_VENDOR_FAILURE,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });
    }else{
      UserService.postCreateVendor(form).then(
        (response) => {
          dispatch({
            type: type.CREATE_VENDOR_SUCCESS,
          });
  
          dispatch({
            type: type.SET_MESSAGE,
            payload: response.data.message,
          });
          dispatch({
            type: type.FINISH_LOADING,
          });
          history.push("/");
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
  
          dispatch({
            type: type.CREATE_VENDOR_FAILURE,
          });
  
          dispatch({
            type: type.SET_MESSAGE,
            payload: message,
          });
          dispatch({
            type: type.FINISH_LOADING,
          });
  
          // Hace logout si recibimos un error 401 => Unauthorized
          if (error.response && error.response.status === 401) {
            console.log("error.response: ", error.response);
            console.log("error.response.status: ", error.response.status);
            EventBus.dispatch("logout");
          }
        }
        );
    }
  },
  (error)=>{
    const message =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString();

  dispatch({
    type: type.CREATE_VENDOR_FAILURE,
  });

  dispatch({
    type: type.SET_MESSAGE,
    payload: message,
    });
  dispatch({
    type: type.FINISH_LOADING,
   });
  });
};
export const editVendor = (form) => async (dispatch) => {
  return userService.postEditVendor(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: type.SET_MESSAGE,
        payload: message,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};

export const getAllCategoryVendor = () => async (dispatch) => {
  dispatch({
    type: type.START_LOADING,
  });
  return UserService.getAllCategoryVendor().then(
    (response) => {
      dispatch({
        type: type.ALL_CATEGORYVENDOR_SUCCESS,
        payload: response.data.result,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });
    },
    (error) => {
      dispatch({
        type: type.ALL_CATEGORYVENDOR_FAILURE,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });
      
      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};

export const getAllVendor = () => async (dispatch) => {
  dispatch({
    type: type.START_LOADING,
  });
  return UserService.getAllVendor().then(
    (response) => {
      dispatch({
        type: type.ALL_VENDOR_SUCCESS,
        payload: response.data.result,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });
    },
    (error) => {
      dispatch({
        type: type.ALL_VENDOR_FAILURE,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};

export const getVendorByUserID = (id) => async (dispatch) => {
  dispatch({
    type: type.START_LOADING,
  });
  return UserService.getVendorByUserID(id).then(
    (response) => {
      dispatch({
        type: type.ALL_VENDOR_SUCCESS,
        payload: response.data.result,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });
    },
    (error) => {
      dispatch({
        type: type.ALL_VENDOR_FAILURE,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};
