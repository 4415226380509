export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_PRIMER_LOGIN = "SET_PRIMER_LOGIN";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const CREATE_VENDOR_SUCCESS = "CREATE_VENDOR_SUCCESS";
export const CREATE_VENDOR_FAILURE = "CREATE_VENDOR_FAILURE";

export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";

export const ALL_USER_SUCCESS = "ALL_USER_SUCCESS";
export const ALL_USER_FAILURE = "ALL_USER_FAILURE";

export const ALL_VENDOR_SUCCESS = "ALL_VENDOR_SUCCESS";
export const ALL_VENDOR_FAILURE = "ALL_VENDOR_FAILURE";

export const ALL_CATEGORYVENDOR_SUCCESS = "ALL_CATEGORYVENDOR_SUCCESS";
export const ALL_CATEGORYVENDOR_FAILURE = "ALL_CATEGORYVENDOR_FAILURE";

export const START_LOADING = "START_LOADING";
export const FINISH_LOADING = "FINISH_LOADING";

export const START_LOADING_FILE = "START_LOADING_FILE";
export const FINISH_LOADING_FILE = "FINISH_LOADING_FILE";

export const FETCH_PROCESS_SUCCESS = "FETCH_PROCESS_SUCCESS";
export const FETCH_PROCESS_FAILURE = "FETCH_PROCESS_FAILURE";

export const FETCH_DOCUMENT_SUCCESS = "FETCH_DOCUMENT_SUCCESS";
export const FETCH_DOCUMENT_FAILURE = "FETCH_DOCUMENT_FAILURE";

export const FETCH_TEMPLATE_SUCCESS = "FETCH_TEMPLATE_SUCCESS";
export const FETCH_TEMPLATE_FAILURE = "FETCH_TEMPLATE_FAILURE";

export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

export const VENDOR_INFO_SUCCESS = "VENDOR_INFO_SUCCESS";
export const VENDOR_INFO_FAILURE = "VENDOR_INFO_FAILURE";

export const FILE_DOWNLOAD_SUCCESS = "FILE_DOWNLOAD_SUCCESS";
export const FILE_DOWNLOAD_FAILURE = "FILE_DOWNLOAD_FAILURE";
