import {
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_FAILURE,
} from "../actions/types";

const initialState = {
  templates: [],
};

export default function templates(templates = initialState, action) {
  switch (action.type) {
    case FETCH_TEMPLATE_SUCCESS:
      return {
        ...templates,
        templates: action.payload,
      };
    case FETCH_TEMPLATE_FAILURE:
      return {
        ...templates,
      };

    default:
      return templates;
  }
}
