// REACT
import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
// LIBRARIES
import { Form, Button, Row, Col } from "react-bootstrap";
// COMPONENTS
import * as lib from "../../functions/functions";
import store from "../../store";
import Header from "../Header/Header";
import Loading from "../loading";
import { history } from "../../helpers/history";
import { getAllUser, resetUserPass } from "../../actions/user";


const Admin = () => {
  const currentUser = store.getState().auth.user;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUser());;
  }, [dispatch]);

  const usuariosfijo = store.getState().user.user;
  let usuarios = store.getState().user.user;
  const [filteredUserList, setfilteredUserList] = useState();
  usuarios = filteredUserList ? filteredUserList : usuarios;

  const [formResetPass, setformResetPass] = useState({
    id: "",
    username: "",
    password: "",
  });

  lib.userAccessbyRol(currentUser, "ADMIN");

  function filterUsers(tipousuario) {
    const result = usuariosfijo.filter((usuario) => usuario.permisos === tipousuario);
    setfilteredUserList(result);
  }

  function handleSubmit(e){
    e.preventDefault();

    dispatch(resetUserPass(formResetPass));
    // Borramos formulario una vez se haya hecho submit
    setformResetPass({
      id: "",
      username: "",
      password: "",
    });
    history.push("/");
    
  }

  function handleSubmitCancel() {
    history.push("/");
  }

  const loading = store.getState().loading;
  return loading ? (
    <Loading />
  ) : (
    <div className="pageContainer">
      <Header />
      <div className="formContainer">
        <h2>Restablecer Contraseña</h2>
        <Form>
        <Form.Group className="mb-3">
            <Row className="g-2">
              <Col md>
                <Form.Label as="h6" sm={2}>
                  Filtrado por Rol
                </Form.Label>
                <Form.Check
                  inline
                  type="radio"
                  label="Administrador"
                  name="FiltradoUsuarios"
                  id="FiltradoUsuarios1"
                  onChange={() => {
                    filterUsers("admin");
                  }}
                />
                <Form.Check
                  required="required"
                  inline
                  type="radio"
                  label="Usuario"
                  name="FiltradoUsuarios"
                  id="FiltradoUsuarios2"
                  onChange={() => {
                    filterUsers("user");
                  }}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Proveedor"
                  name="FiltradoUsuarios"
                  id="FiltradoUsuarios3"
                  onChange={() => {
                    filterUsers("vendor");
                  }}
                />
              </Col>
            </Row>
          </Form.Group>
          </Form>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Nombre Cuenta</Form.Label>
            <Form.Label as="h5">Cuenta Usuario</Form.Label>
                <Form.Select
                  required="required"
                  value={formResetPass.username}
                  onChange={(e) =>
                    setformResetPass({
                      ...formResetPass,
                      id: usuarios.filter(
                        (u) => u.usuario === e.target.value
                        )[0].idUsuario,
                      username: e.target.value,
                      password: lib.generatePassword(),
                    })
                  }
                >
                  <option value="" hidden>
                    ---
                    </option>
                  {usuarios.map((user) => (
                      <option key={user.idUsuario} value={user.usuario}>
                      {" "}
                      {user.usuario}
                      {user.nombreProveedor ? " - " : ""}
                      {user.nombreProveedor}
                    </option>
                  ))}
                </Form.Select>
          </Form.Group>
          <Button variant="secondary" type="submit" className="btn-create-form">
            Restablecer
          </Button>
        </Form>
        <Form onSubmit={handleSubmitCancel}>
        <Button variant="secondary" type="submit" className="btn-create-form">
            Cancelar
          </Button>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

export default connect(mapStateToProps)(Admin);
