import { FETCH_PROCESS_SUCCESS, FETCH_PROCESS_FAILURE } from "../actions/types";

const initialState = {
  process: [],
};

export default function user(process = initialState, action) {
  switch (action.type) {
    case FETCH_PROCESS_SUCCESS:
      return {
        ...process,
        process: action.payload,
      };
    case FETCH_PROCESS_FAILURE:
      return {
        ...process,
      };
    default:
      return process;
  }
}
