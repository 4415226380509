import { START_LOADING, FINISH_LOADING } from "../actions/types";

const initialState = false;

export default function loading(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case START_LOADING:
      return true;

    case FINISH_LOADING:
      return false;

    default:
      return state;
  }
}
