import * as type from "./types";
import EventBus from "../common/EventBus";
import UserService from "../services/user.service";
import { history } from "../helpers/history";
import { logout } from "./auth";

export const createUser = (form) => async (dispatch) => {
  dispatch({
    type: type.START_LOADING,
  });
  return UserService.postCreateUser(form).then(
    (response) => {
      dispatch({
        type: type.CREATE_USER_SUCCESS,
        payload: form,
      });

      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });  

      if(response.data.message!=='KO'){
        history.push("/");
      }
      dispatch({
        type: type.FINISH_LOADING,
      });
      
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: type.CREATE_USER_FAILURE,
      });

      dispatch({
        type: type.SET_MESSAGE,
        payload: message,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};

export const getAllUser = () => async (dispatch) => {
  dispatch({
    type: type.START_LOADING,
  });
  return UserService.getAllUser().then(
    (response) => {
      dispatch({
        type: type.ALL_USER_SUCCESS,
        payload: response.data.result,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });
    },
    (error) => {
      dispatch({
        type: type.ALL_USER_FAILURE,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};

export const getUser = (id) => async (dispatch) => {
  const form = {id: id};
  console.log(form);
  dispatch({
    type: type.START_LOADING,
  });
  return UserService.getUser(form).then(
    (response) => {
      dispatch({
        type: type.ALL_USER_SUCCESS,
        payload: response.data.result,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });
    },
    (error) => {
      dispatch({
        type: type.ALL_USER_FAILURE,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};

export const modifyUser = (form) => async (dispatch) => {
  dispatch({
    type: type.START_LOADING,
  });
  return UserService.postModifyUser(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });
      if(response.data.message !== "KO"){
        dispatch(logout())
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: type.SET_MESSAGE,
        payload: message,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};
export const changeUserPass = (form) => async (dispatch) => {
  return UserService.postChangeUserPass(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: type.SET_MESSAGE,
        payload: message,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};
export const resetUserPass = (form) => async (dispatch) => {
  return UserService.postResetUserPass(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });

  },

    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: type.SET_MESSAGE,
        payload: message,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};
