// REACT
import React, { useEffect } from "react";
// LIBRARIES
// COMPONENTS
import store from "../../store";
import * as config from "../../config/config";
import AdminHeader from "./AdminHeader";
import UserHeader from "./UserHeader";
import VendorHeader from "./VendorHeader";
import { history } from "../../helpers/history";
import axios from "axios";
import authHeader from "../../services/auth-header";


const Header = () => {
  const currentUser = store.getState().auth.user;

  useEffect(() => {

    const urlUser = config.urlServer + "/user";
    //const isVendor = store.getState().auth.user?.role === "vendor";
    const usuarioid = store.getState().auth.user.id;
    //if (isVendor) {
      const form = {id: usuarioid};
      async function fetchData() {
        const response = await axios.get( urlUser + "/getuser", { params: form, headers: authHeader() });
        const cambiarPass = store.getState().auth.user?.cambiarPass;
        const role = store.getState().auth.user?.role;

        const arraydatos = {bln_primerLogin: response.data.result[0].bln_primerLogin
                      ,idUsuario: response.data.result[0].idUsuario
                      ,cambiarPass: cambiarPass
                      ,role: role}
       
        return arraydatos;
      }

        
        fetchData().then(respuesta => 
          //console.log(respuesta.idUsuario+" "+respuesta.bln_primerLogin+" "+respuesta.cambiarPass)
          {
               if (respuesta.cambiarPass) {
                  history.push("/user/changePass");
                }

              if (!respuesta.cambiarPass && respuesta.bln_primerLogin && respuesta.role === "vendor") {
                  history.push("/info"); 
          }
        }
             
        );
    //}

  }, []);




  // useEffect(() => {
  //     dispatch(getUser(currentUser));
  // }, [currentUser]);

  // useEffect(() => {
  //   prueba(store.getState().auth.user.id);
  //   //getUser(35);
  //   console.log("1");
  //   console.log(store.getState());
  //   const primerLogin = store.getState().auth.user?.primerLogin;
  //   const role = store.getState().auth.user?.role;
  //   const cambiarPass = store.getState().auth.user?.cambiarPass;

  //   // if (role === "vendor")
  //   // {
  //   //   dispatch(getUser(35));
  //   // }

  //   if (cambiarPass) {
  //     history.push("/user/changePass");
  //   }

  //   if (!cambiarPass && primerLogin && role === "vendor") {
  //     history.push("/info");
  //   }
  // }, [USERID]);

  switch (currentUser.role) {
    case config.ROLES.ADMIN:
      return <AdminHeader />;
    case config.ROLES.USER:
      return <UserHeader />;
    case config.ROLES.VENDOR:
      return <VendorHeader />;

    default:
      return <div></div>;
  }
};
export default Header;
