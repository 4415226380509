import {
  ALL_USER_SUCCESS,
  ALL_USER_FAILURE,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
} from "../actions/types";

const initialState = {
  user: [],
};

export default function user(users = initialState, action) {
  switch (action.type) {
    case ALL_USER_SUCCESS:
      return {
        ...users,
        user: action.payload,
      };
    case ALL_USER_FAILURE:
      return {
        ...users,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...users,
      };
    case CREATE_USER_FAILURE:
      return {
        ...users,
      };
    default:
      return users;
  }
}
