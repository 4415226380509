import * as type from "./types";
import EventBus from "../common/EventBus";
import UserService from "../services/user.service";
import { history } from "../helpers/history";

export const getAllProcess = () => async (dispatch) => {
  dispatch({
    type: type.START_LOADING,
  });
  return UserService.getAllProcess().then(
    (response) => {
      dispatch({
        type: type.FETCH_PROCESS_SUCCESS,
        payload: response.data.result,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });
    },
    (error) => {
      console.log(error);
      dispatch({
        type: type.FETCH_PROCESS_FAILURE,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};
export const createProcess = (form) => async (dispatch) => {
  return UserService.postCreateProcess(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
      history.push("/");
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: type.SET_MESSAGE,
        payload: message,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};

export const cancelarProceso = (form) => async (dispatch) => {
  return UserService.CancelarProceso(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
      //window.location.reload(false);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: type.SET_MESSAGE,
        payload: message,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};

export const cancelarProcesoSinDeshomologar = (form) => async (dispatch) => {
  return UserService.CancelarProcesoSinDeshomologar(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });

    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: type.SET_MESSAGE,
        payload: message,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};

export const bloquearProceso = (form) => async (dispatch) => {
  return UserService.BloquearProceso(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
      

    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: type.SET_MESSAGE,
        payload: message,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};


export const desbloquearProceso = (form) => async (dispatch) => {
  return UserService.DesbloquearProceso(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
      //window.location.reload(false);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: type.SET_MESSAGE,
        payload: message,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};

export const getProcess = (form) => async (dispatch) => {
  dispatch({
    type: type.START_LOADING,
  });
  return UserService.getProcess(form).then(
    (response) => {
      dispatch({
        type: type.FETCH_PROCESS_SUCCESS,
        payload: response.data.result,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });
    },
    (error) => {
      dispatch({
        type: type.FETCH_PROCESS_FAILURE,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};
