// REACT
import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
// LIBRARIES
import { useParams } from "react-router-dom";
import { Table, Button, Form, Modal, Col } from "react-bootstrap";
import * as config from "../../config/config";

// COMPONENTS
import * as lib from "../../functions/functions";
import store from "../../store";
import Loading from "../loading";
import Header from "../Header/Header";
import {
  getDocuments,
  sendFormRev,
  sendFormRevCloseProcess,
  notifyVendorDoc,
  modificarFecProxRenov,
} from "../../actions/documents";
import { history } from "../../helpers/history";
import { cancelarProcesoSinDeshomologar, bloquearProceso, desbloquearProceso } from "../../actions/process";

const Procesos = () => {
  const documents = store.getState().documents.documents;
  const proc = store.getState().process.process;
  const currentUser = store.getState().auth.user;
  const dispatch = useDispatch();
  const { user, id } = useParams();

  const [show, setShow] = useState(false);
  const [comment, setComment] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showNotificar, setShowNotificar] = useState(false);
  const handleCloseNotificar = () => setShowNotificar(false);
  const handleShowNotificar = () => setShowNotificar(true);

  const [showBloqueado, setShowBloqueado] = useState(false);
  const handleCloseBloqueado = () => setShowBloqueado(false);
  const handleShowBloqueado = () => setShowBloqueado(true);

  const [showModificar, setShowModificar] = useState(false);
  const handleCloseModificar = () => setShowModificar(false);
  const handleShowModificar = () => setShowModificar(true);

  const[blnCancelado, setCancelado] = useState(false);
  const[blnCompletado, setCompletado] = useState(false);

  const [fecProxRenov, setFecProxRenov] = useState("");
  const [razonsocial, setRazonSocial] = useState("");
  const [cifnif, setCifNif] = useState("");

  function clickOnDoc(index) {


    if (proc[0]?.estado === "Completado"){
      setCompletado(true);
    }
    else if (proc[0]?.estado === "Cancelado") {
      setCancelado(true);
    }
    else {
      setCompletado(false);
      setCancelado(false);
    }
    if (currentUser.role === "vendor") {
      const notValid =
        store.getState().documents?.documents[index - 1].estado !== "Validado";
      if (notValid) {
        if (proc[0].estado === "Bloqueado") {
          handleShowBloqueado();
        } 
        else {
          history.push("/procesos/" + user + "/" + id + "/doc/" + index);
        }
      }
    } else {
      const fileExists =
        store.getState().documents?.documents[index - 1].estado !==
        "Pendiente de Adjuntar";
      if (fileExists) {
        if (store.getState().documents?.documents[index - 1].estado === "N/A") {
          const commentProv =
            store.getState().documents?.documents[index - 1]
              ?.comentarioProveedor;
          setComment(commentProv);
          handleShow();
        } 
        // else if (
        //   store
        //     .getState()
        //     .documents?.documents[index - 1].comentarioProveedor.includes(
        //       "N/A: "
        //     )
        // ) 
        // { 
        //   const commentProv =
        //     store.getState().documents?.documents[index - 1]
        //       ?.comentarioProveedor;
        //   setComment(commentProv);
        //   handleShow();
        // } 
        else {
          let docUrl = "";
          if (store.getState().process.process) {
            function replaceAll(str, find, replace) {
              if (str) return str.replace(new RegExp(find, "g"), replace);
            }
            const docName = replaceAll(documents[index - 1]?.nombre, "/", "_");
            const extension = documents[index - 1]?.extensionDoc;
            //docUrl = user + "/" + proc[0]?.descripcion + "/" + docName + ".pdf";
            //docUrl = user + "/" + proc[0]?.tipoProceso + "_" + proc[0]?.fecIniValidez.replace(new RegExp("/","g"),"_") + "/" + docName + ".pdf";
            docUrl = user + "/" + proc[0]?.tipoProceso + "_" + proc[0]?.fecIniValidez.replace(new RegExp("/","g"),"_") + "/" + docName + extension;
          }

          let url = config.urlFilesHost + docUrl;
          window.open(url, "_blank");
        }
      } else {
        history.push("/procesos/" + user + "/" + id + "/doc/" + index);
      }
    }
  }

  function DesbloquearProceso() {
    const  idProcesoPulsado = proc[0]?.idProceso;
    dispatch(desbloquearProceso({idProcesoPulsado}));
    history.push("/");
    //handleClose();
  }

  function CancelarProcesoSinDeshomologar() {
    const form = {
      idProcesoPulsado: proc[0]?.idProceso,
    }
    dispatch(cancelarProcesoSinDeshomologar(form));
    history.push("/");
    //handleClose();
  }
  function BloquearProceso() {
    const form = {
      idProcesoPulsado: proc[0]?.idProceso,
    }
    dispatch(bloquearProceso(form));
    history.push("/");
    //handleClose();
  }

  function handleModificarFecha() {
    dispatch(
      modificarFecProxRenov({
        idProceso: proc[0].idProceso,
        fecProxRenov: fecProxRenov,
      })
    );
  }

  function handleContinueModal() {
    handleCloseNotificar();
    const form = {
      //mailEmpresa: proc[0]?.mailEmpresa,
      mailProv: proc[0]?.mailContacto,
    };
    documents.map((doc) => {
      form[doc.idDocumento] = `${doc.nombre}: <b>${doc.estado}</b>`;
      return doc;
    });

    dispatch(notifyVendorDoc(form));
  }

  function handleSubmitCancel() {
    history.push("/");
  }

  const loading = store.getState().loading;

  useEffect(() => {
    dispatch(getDocuments({ userName: user, processID: id }));
  }, [dispatch, id, user]);

  useEffect(() => {
    if (proc[0]?.fecProxRenovacion) {
      setFecProxRenov(proc[0]?.fecProxRenovacion);
    }
    if (proc[0]?.razonSocial) {
      setRazonSocial(proc[0]?.razonSocial);
    }
    if (proc[0]?.cifnif) {
      setCifNif(proc[0]?.cifnif);
    }
    if (proc[0]?.estado === "Completado"){
      setCompletado(true);
    }
    else if (proc[0]?.estado === "Cancelado") {
      setCancelado(true);
    }
    else {
      setCompletado(false);
      setCancelado(false);
    }
  }, [loading, proc]);


  // check if user is logged in
  lib.userAccess(currentUser);

  const [actualizarestado, setActualizarEstado] = useState("");

  return loading ? (
    <Loading />
  ) : (
    <div className="pageContainer">
      <Header />
      {currentUser.role === "vendor" ? (
        <div className="TableContainer">
          <Table className="styled-table" responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Documento</th>
                <th>Estado</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {store.getState().documents.documents.map((doc, index) => (
                <tr
                  className={
                    currentUser.role === "vendor"
                      ? doc.estado === "Validado"
                        ? ""
                        : "clickable"
                      : "clickable"
                  }
                  key={doc.nombre}
                  onClick={() => clickOnDoc(index + 1)}
                >
                  <td>{index + 1}</td>
                  <td>{doc.nombre}</td>
                  <td>{doc.estado}</td>
                  <td>
                    {" "}
                    {currentUser.role === "vendor"
                      ? doc.estado === "Validado"
                        ? ""
                        : ">"
                      : ">"}{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="TableContainer">
          <h5>Razón Social: {razonsocial}</h5>
          <h5>CIF/NIF: {cifnif}</h5>
          <h5>Fecha próxima renovación: </h5>
          <div className="modifyFecProxRenov">
            <Col>
            <Form.Control
              type="text"
              className="fecProxRenov"
              placeholder="dd/mm/yyyy"
              value={fecProxRenov}
              onChange={(e) => setFecProxRenov(e.target.value)}
              />
            </Col>
            <Col>
            <Button
              variant="secondary"
              type="submit"
              className="btn-create-form"
              onClick={() => handleShowModificar()}
            >
              Modificar
            </Button>
            
            </Col>
            <Col>
            <Form.Select
                  hidden={blnCompletado}
                  required="required"
                  value={actualizarestado}
                  onChange={(e) => {
                    setActualizarEstado(e.target.value);
                  }
                  }
                >
                <option value="" hidden>
                  ---
                </option>
                <option value="En proceso" hidden={!blnCancelado}>En proceso</option>
                <option value="Bloqueado">Bloqueado</option>
                <option value="Cancelado" hidden={blnCancelado}>Cancelado</option>
                </Form.Select>
            </Col>
            <Col>
            </Col>
            <Col>
            <Button
              hidden={blnCompletado}
              variant="secondary"
              type="submit"
              className="btn-create-form"
              onClick={() => { 
                if(actualizarestado === "Cancelado")
                {CancelarProcesoSinDeshomologar()}
                else if(actualizarestado === "Bloqueado")
                {BloquearProceso()}
                else if(actualizarestado === "En proceso")
                {DesbloquearProceso()}}
              }
                
            >
              Actualizar Estado Proceso
            </Button>
            </Col>
            
          </div>
          <Table className="styled-table" responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Documento</th>
                <th>Estado</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {store.getState().documents?.documents.map((doc, index) => {
                const inputRef = React.createRef();
                return (
                  <tr className="clickable" key={doc.nombre}>
                    <td onClick={() => clickOnDoc(index + 1)}>{index + 1}</td>
                    <td onClick={() => clickOnDoc(index + 1)}>{doc.nombre}</td>
                    <td onClick={() => clickOnDoc(index + 1)}>{doc.estado}</td>
                    <td>
                      {" "}
                      <Form.Control
                        type="text"
                        placeholder=
                          {(doc.comentario==="" || doc.comentario===null) ? "Comentario" : doc.comentario}
                        
                        ref={inputRef}
                        style={{
                          visibility: ![
                            //"Validado",
                            "Pendiente de Adjuntar",
                          ].includes(doc.estado)
                          &&
                          proc[0].estado === "En proceso"
                            ? "visible"
                            : "hidden",
                        }}
                      />
                    </td>
                    <td>
                      <Button
                        variant="success"
                        style={{
                          display: ![
                            //"Validado",
                            "Pendiente de Adjuntar",
                          ].includes(doc.estado)
                          &&
                          proc[0].estado === "En proceso"
                            ? "block"
                            : "none",
                        }}
                        onClick={() => {
                          const formRev = {
                            tipo: "revision",
                            user: user,
                            id: id,
                            idProceso: proc[0]?.idProceso,
                            doc: index + 1,
                            idDocumento: documents[index]?.idDocumento,
                            estado: "Validado",
                            comment: inputRef.current.value,
                          };

                          let docAceptados = 0;
                          documents.map((doc) => {
                            if (doc.estado === "Validado") {
                              docAceptados += 1;
                            }
                            return docAceptados;
                          });

                          if (documents.length - docAceptados === 1) {
                            dispatch(sendFormRevCloseProcess(formRev));
                          } else {
                            dispatch(sendFormRev(formRev));
                          }
                        }}
                      >
                        ✓
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        style={{
                          display: ![
                            //"Validado",
                            "Pendiente de Adjuntar",
                          ].includes(doc.estado)
                          &&
                          proc[0].estado === "En proceso"
                            ? "block"
                            : "none",
                        }}
                        onClick={() => {
                          dispatch(
                            sendFormRev({
                              tipo: "revision",
                              user: user,
                              id: id,
                              idProceso: proc[0]?.idProceso,
                              doc: index + 1,
                              idDocumento: documents[index]?.idDocumento,
                              estado: "Denegado",
                              comment: inputRef.current.value,
                              nombreDocumento: documents[index]?.nombre,
                              mailprov: proc[0]?.mailContacto,
                            })
                          );
                        }}
                      >
                        x
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="sendMailtoVendorButton">
            <Button
              variant="secondary"
              type="submit"
              className="btn-create-form"
              onClick={() => handleShowNotificar()}
            >
              Notificar situación al Proveedor
            </Button
            >
          </div>
          <div className="sendMailtoVendorButton">
            <Button
              variant="secondary"
              type="submit"
              className="btn-create-form"
              onClick={() => handleSubmitCancel()}
            >
              Cancelar
            </Button
            >
          </div>
        </div>
        
      )}
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Comentario del proveedor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{comment}</Modal.Body>
        <Modal.Footer>
          {/* {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal centered show={showNotificar} onHide={handleCloseNotificar}>
        <Modal.Header closeButton>
          <Modal.Title>Notificar al proveedor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Esta acción notificará al proveedor mediante un correo electrónico del
          estado actual de los documentos.
        </Modal.Body>
        <Modal.Body>¿Desea Continuar?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn-create-form"
            onClick={handleContinueModal}
          >
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal centered show={showBloqueado} onHide={handleCloseBloqueado}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Proceso bloqueado
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Su proceso ha sido bloqueado por haber excedido el tiempo para
          adjuntar la documentación.
        </Modal.Body>
        <Modal.Body>
          Por favor, pongase en contacto con un administrador en el correo compliance@redwoodcorp.com para desbloquear
          su proceso.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn-create-form"
            onClick={handleCloseBloqueado}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered show={showModificar} onHide={handleCloseModificar}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modificar fecha próxima renovación
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Vas a modificar la fecha de próxima renovación, este cambio puede
          provocar, que se creen nuevos procesos, y se notifican a los usuarios
          por correo.
        </Modal.Body>
        <Modal.Body>¿Quieres continuar?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn-create-form"
            onClick={handleModificarFecha}
          >
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
    documents: state.documents,
  };
};

export default connect(mapStateToProps)(Procesos);
