// REACT
import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
// LIBRARIES
import { Form, Button, Row, Col } from "react-bootstrap";
// COMPONENTS
import * as lib from "../../functions/functions";
import store from "../../store";
import Header from "../Header/Header";
import Loading from "../loading";
import { getAllVendor } from "../../actions/vendor";
import { getAllCategoryVendor } from "../../actions/vendor";
import { history } from "../../helpers/history";
import { editVendorInfo } from "../../actions/info";

const Editar = () => {
  const currentUser = store.getState().auth.user;
  const loading = store.getState().loading;
  const dispatch = useDispatch();

  const [blnComercializadora, setblnComercializadora] = useState(true);
  const [blnOtros, setblnOtros] = useState(true);
  const [blnDatosPers, setblnDatosPers] = useState(true);

  const [formDataProv, setformDataProv] = useState({
    userID: "",
    EstadoProveedor: "",
    CorreoProveedor: "",
    CorreoOwner: "",
    Categoria: "",

    RazonSocial: "",
    CIFNIF: "",
    DenComercial: "",
    IBAN: "",
    Domicilio: "",
    Localidad: "",
    Provincia: "",
    CP: "",
    Telefono: "",
    //Mail: "",
    PaginaWeb: "",

    //TipoServicio: "",
    ZonaTrabajo: "",
    Otros: "",
    Datospersonales: "",
    Detalle: "",

    Nombre_contacto: "",
    Apellido1_contacto: "",
    Apellido2_contacto: "",
    Mail_contacto: "",
    Telefono_contacto: "",

    Nombre_firmante: "",
    DatosRepresentacion_firmante: "",
    Apellido1_firmante: "",
    Apellido2_firmante: "",
    Mail_firmante: "",
    Telefono_firmante: "",
    NivelRiesgoGDPR: "",
    FecDeshomologacion: "",
    MotivoDeshomologacion: "",
  });

  function updateEditForm(vendor) {
     const filtered = store
       .getState()
       .vendor.vendor.filter((vend) => vend.nombreProveedor === vendor);

    const vend = filtered[0];
    if (vend.accesoDatosPersonales === "Si") {
      setblnDatosPers(false);
    } else {
      setblnDatosPers(true);
    }
    if (vend.categoriaProveedor === "Broker") {
      setblnComercializadora(false);
      setblnOtros(true);
    } else if (vend.categoriaProveedor === "Otros servicios") {
      setblnOtros(false);
      setblnComercializadora(true);
    } else {
      setblnComercializadora(true);
      setblnOtros(true);
    }
    setformDataProv({
      userID: vend.idUsuario ? vend.idUsuario : "",
      EstadoProveedor: vend.estadoProveedor ? vend.estadoProveedor : "",
      CorreoProveedor: vend.correoProveedor ? vend.correoProveedor : "",
      CorreoOwner: vend.correoOwner ? vend.correoOwner : "",
      Categoria: vend.categoriaProveedor ? vend.categoriaProveedor : "",

      RazonSocial: vend.razonSocial ? vend.razonSocial : "",
      CIFNIF: vend.CIFNIF ? vend.CIFNIF : "",
      DenComercial: vend.denomComercial ? vend.denomComercial : "",
      IBAN: vend.IBAN ? vend.IBAN : "",
      Domicilio: vend.domicilio ? vend.domicilio : "",
      Localidad: vend.localidad ? vend.localidad : "",
      Provincia: vend.provincia ? vend.provincia : "",
      CP: vend.CP ? vend.CP : "",
      Telefono: vend.telefonoEmpresa ? vend.telefonoEmpresa : "",
      //Mail: vend.mailEmpresa ? vend.mailEmpresa : "",
      PaginaWeb: vend.paginaWeb ? vend.paginaWeb : "",

      //TipoServicio: vend.tipoServicio ? vend.tipoServicio : "",
      ZonaTrabajo: vend.zonaTrabajo ? vend.zonaTrabajo : "",
      Otros: vend.otros ? vend.otros : "",
      Datospersonales: vend.accesoDatosPersonales
        ? vend.accesoDatosPersonales
        : "",
      Detalle: vend.detalleAccesoDatPers ? vend.detalleAccesoDatPers : "",

      Nombre_contacto: vend.nombreContacto ? vend.nombreContacto : "",
      Apellido1_contacto: vend.apellido1Contacto ? vend.apellido1Contacto : "",
      Apellido2_contacto: vend.apellido2Contacto ? vend.apellido2Contacto : "",
      Mail_contacto: vend.mailContacto ? vend.mailContacto : "",
      Telefono_contacto: vend.telefonoContacto ? vend.telefonoContacto : "",

      Nombre_firmante: vend.nombreFirmante ? vend.nombreFirmante : "",
      DatosRepresentacion_firmante: vend.datosDeRepresentacion
        ? vend.datosDeRepresentacion
        : "",
      Apellido1_firmante: vend.apellido1Firmante ? vend.apellido1Firmante : "",
      Apellido2_firmante: vend.apellido2Firmante ? vend.apellido2Firmante : "",
      Mail_firmante: vend.mailFirmante ? vend.mailFirmante : "",
      Telefono_firmante: vend.telefonoFirmante ? vend.telefonoFirmante : "",
      NivelRiesgoGDPR: vend.nivelRiesgoGDPR ? vend.nivelRiesgoGDPR : "",
      FecDeshomologacion: vend.fecDeshomologacion
        ? vend.fecDeshomologacion
        : "",
      MotivoDeshomologacion: vend.motivoDeshomologacion
        ? vend.motivoDeshomologacion
        : "",
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const edit = new Promise((resolve, reject) => {
      dispatch(editVendorInfo(formDataProv));
      resolve();
    });
    edit.then(() => history.push("/"));
  }

  function handleSubmitCancel() {
    history.push("/");
  }

  useEffect(() => {
    dispatch(getAllVendor());
  }, [dispatch]);

  const proveedores = store.getState().vendor?.vendor;

  useEffect(() => {
    dispatch(getAllCategoryVendor());
  }, [dispatch]);

  const categoriasproveedor = store.getState().vendorcategory?.vendorcategory;

  lib.userAccessbyRoles(currentUser, "ADMIN", "USER");

  return loading ? (
    <Loading />
  ) : (
    <div className="pageContainer">
      <Header />
      <div className="formContainer">
        <h2>Editar Proveedor</h2>

        <Form onSubmit={handleSubmit} className="vendorForm">
          <Form.Label>Escoge un proveedor</Form.Label>
          <Form.Select
            required="required"
            onChange={(e) => {
              updateEditForm(e.target.value);
            }}
          >
            <option value="" hidden>
              ---
            </option>
            {proveedores.map((vendor) => (
              <option key={vendor.idProveedor} value={vendor.nombreProveedor}>
                {" "}
                {vendor.nombreProveedor}{" "}
              </option>
            ))}
          </Form.Select>
          <h5>Datos del Proveedor</h5>
          <Row>
            <Col>
              <Form.Label>Estado del proveedor</Form.Label>
              <Form.Select
                required="required"
                value={formDataProv.EstadoProveedor}
                onChange={(e) => {
                  setformDataProv({
                    ...formDataProv,
                    EstadoProveedor: e.target.value,
                  });
                }}
              >
                <option value="" hidden>
                  ---
                </option>
                <option value="Pdte asignar">Pdte asignar</option>
                <option value="En Curso">En Curso</option>
                <option value="Pdte validación">Pdte validación</option>
                <option value="Homologado">Homologado</option>
                <option value="Deshomologado">Deshomologado</option>
                <option value="Exento">Exento</option>
              </Form.Select>
            </Col>

            <Col>
              <Form.Label>Nivel de riesgo GDPR</Form.Label>
              <Form.Select
                value={formDataProv.NivelRiesgoGDPR}
                onChange={(e) => {
                  setformDataProv({
                    ...formDataProv,
                    NivelRiesgoGDPR: e.target.value,
                  });
                }}
              >
                <option value="" hidden>
                  ---
                </option>
                <option value="Bajo">Bajo</option>
                <option value="Medio">Medio</option>
                <option value="Alto">Alto</option>
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>Fecha de deshomologación</Form.Label>
              <Form.Control
                type="text"
                placeholder="DD/MM/YYYY"
                required={formDataProv.EstadoProveedor === "Deshomologado"}
                value={formDataProv.FecDeshomologacion}
                onChange={(e) =>
                  setformDataProv({
                    ...formDataProv,
                    FecDeshomologacion: e.target.value,
                  })
                }
              />
            </Col>
            <Col>
              <Form.Label>Motivo de deshomologación</Form.Label>
              <Form.Control
                type="text"
                placeholder="Motivo de Deshomologacion"
                required={formDataProv.EstadoProveedor === "Deshomologado"}
                value={formDataProv.MotivoDeshomologacion}
                onChange={(e) =>
                  setformDataProv({
                    ...formDataProv,
                    MotivoDeshomologacion: e.target.value.slice(0,200),
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            {/* <Col>
              <Form.Label>Correo del Proveedor</Form.Label>
              <Form.Control
                type="text"
                placeholder="Correo del Proveedor"
                required
                value={formDataProv.CorreoProveedor}
                onChange={(e) =>
                  setformDataProv({
                    ...formDataProv,
                    CorreoProveedor: e.target.value,
                  })
                }
              />
            </Col> */}
            <Col>
              <Form.Label>Correo del Owner</Form.Label>
              <Form.Control
                type="text"
                placeholder="Correo del Owner"
                required
                value={formDataProv.CorreoOwner}
                onChange={(e) =>
                  setformDataProv({
                    ...formDataProv,
                    CorreoOwner: e.target.value.slice(0,100),
                  })
                }
              />
            </Col>
            <Col>
              <Form.Label>Categoría Proveedor</Form.Label>
              <Form.Select
                required="required"
                value={formDataProv.Categoria}
                onChange={(e) => {
                  setformDataProv({
                    ...formDataProv,
                    Categoria: e.target.value,
                  })
                  ;
                if (e.target.value === "Broker") {
                  setblnComercializadora(false);
                  setblnOtros(true);
                } else if (e.target.value === "Otros servicios") {
                  setblnOtros(false);
                  setblnComercializadora(true);
                } else {
                  setblnComercializadora(true);
                  setblnOtros(true);
                }
                }}
              >
                <option value="" hidden>
                  ---
                </option>
                {categoriasproveedor.map((categoria) => (
                    <option key={categoria.categoriaProveedor} value={categoria.categoriaProveedor}>
                    {" "}
                    {categoria.categoriaProveedor}{" "}
                  </option>
                  ))} 
                
              </Form.Select>
            </Col>
          </Row>
          <h5>Datos de la Empresa</h5>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Row>
              <Col>
                <Form.Label>Razón Social</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Razón Social (incluyendo titularidad jurídica)"
                  required="required"
                  value={formDataProv.RazonSocial}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      RazonSocial: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>CIF/NIF</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="CIF/NIF"
                  required="required"
                  value={formDataProv.CIFNIF}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      CIFNIF: e.target.value.slice(0,10),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Denominación Comercial</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Denominación Comercial"
                  required="required"
                  value={formDataProv.DenComercial}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      DenComercial: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
            </Row>
            <Form.Label>Cuenta Bancaria (IBAN)</Form.Label>
            <Form.Control
              type="text"
              placeholder="IBAN"
              required="required"
              value={formDataProv.IBAN}
              onChange={(e) =>
                setformDataProv({
                  ...formDataProv,
                  IBAN: e.target.value.slice(0,100),
                })
              }
            />
            <Row>
              <Col>
                <Form.Label>Domicilio</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Domicilio (calle, número, portal...)"
                  required="required"
                  value={formDataProv.Domicilio}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Domicilio: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Localidad</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Localidad"
                  required="required"
                  value={formDataProv.Localidad}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Localidad: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Provincia</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Provincia"
                  required="required"
                  value={formDataProv.Provincia}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Provincia: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Código Postal</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Código Postal"
                  required="required"
                  value={formDataProv.CP}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      CP: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Teléfono contacto"
                  required="required"
                  value={formDataProv.Telefono}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Telefono: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              {/* <Col>
                <Form.Label>Dirección de e-mail</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Dirección de correo electrónico"
                  required="required"
                  value={formDataProv.Mail}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Mail: e.target.value,
                    })
                  }
                />
              </Col> */}
              <Col>
                <Form.Label>Página web</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Página web"
                  //required="required"
                  value={formDataProv.PaginaWeb}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      PaginaWeb: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
            </Row>
          </Form.Group>

          <h5>Datos del Servicio</h5>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Group hidden={blnComercializadora}>
              <Form.Label>Zona de trabajo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Zona de Trabajo"
                required={!blnComercializadora}
                value={formDataProv.ZonaTrabajo}
                onChange={(e) =>
                  setformDataProv({
                    ...formDataProv,
                    ZonaTrabajo: e.target.value.slice(0,100),
                  })
                }
              />
            </Form.Group>
            <Form.Group hidden={blnOtros}>
              <Form.Label>Otros</Form.Label>
              <Form.Control
                type="text"
                placeholder="Tipo de servicio"
                required={!blnOtros}
                value={formDataProv.Otros}
                onChange={(e) =>
                  setformDataProv({
                    ...formDataProv,
                    Otros: e.target.value.slice(0,100),
                  })
                }
              />
            </Form.Group>
            <Form.Label>Acceso a datos personales</Form.Label>
            <Form.Select
              required="required"
              value={formDataProv.Datospersonales}
              onChange={(e) => {
                setformDataProv({
                  ...formDataProv,
                  Datospersonales: e.target.value,
                });

                if (e.target.value === "Si") {
                  setblnDatosPers(false);
                } else {
                  setblnDatosPers(true);
                }
              }}
            >
              <option value="" hidden>
                ---
              </option>
              <option value="Si">Si</option>
              <option value="No">No</option>
            </Form.Select>
            <Form.Group hidden={blnDatosPers}>
              <Form.Label>Detalle datos personales</Form.Label>
              <Form.Control
                type="text"
                placeholder="Especificar qué datos personales trata: nombre, DNI, datos de contacto, situación laboral, etc..."
                required={!blnDatosPers}
                value={formDataProv.Detalle}
                onChange={(e) =>
                  setformDataProv({
                    ...formDataProv,
                    Detalle: e.target.value.slice(0,100),
                  })
                }
              />
            </Form.Group>
          </Form.Group>
          <h5>Datos de contacto</h5>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Row>
              <Col>
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  required="required"
                  value={formDataProv.Nombre_contacto}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Nombre_contacto: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Apellido</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Primer Apellido"
                  required="required"
                  value={formDataProv.Apellido1_contacto}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Apellido1_contacto: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Apellido 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Segundo Apellido"
                  required="required"
                  value={formDataProv.Apellido2_contacto}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Apellido2_contacto: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Correo Proveedor</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Dirección de correo"
                  required="required"
                  value={formDataProv.Mail_contacto}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Mail_contacto: e.target.value.slice(0,100),
                      CorreoProveedor: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Teléfono de Contacto"
                  required="required"
                  value={formDataProv.Telefono_contacto}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Telefono_contacto: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
            </Row>
          </Form.Group>
          <h5>Datos de firmante del contrato</h5>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Row>
              <Col>
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  required="required"
                  value={formDataProv.Nombre_firmante}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Nombre_firmante: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Apellido</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Primer Apellido"
                  required="required"
                  value={formDataProv.Apellido1_firmante}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Apellido1_firmante: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Apellido 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Segundo Apellido"
                  required="required"
                  value={formDataProv.Apellido2_firmante}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Apellido2_firmante: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
            </Row>
            <Form.Label>Datos de representación</Form.Label>
            <Form.Control
              className="formDatosRep"
              type="text"
              placeholder="(cargo, notario, protocolo, fecha)"
              required="required"
              value={formDataProv.DatosRepresentacion_firmante}
              onChange={(e) =>
                setformDataProv({
                  ...formDataProv,
                  DatosRepresentacion_firmante: e.target.value.slice(0,100),
                })
              }
            />
            <Row>
              <Col>
                <Form.Label>Correo firmante</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Dirección de correo"
                  required="required"
                  value={formDataProv.Mail_firmante}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Mail_firmante: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Teléfono"
                  required="required"
                  value={formDataProv.Telefono_firmante}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      Telefono_firmante: e.target.value.slice(0,100),
                    })
                  }
                />
              </Col>
            </Row>
          </Form.Group>
          <Button variant="secondary" type="submit" className="btn-create-form">
            Guardar
          </Button>
        </Form>
        <Form onSubmit={handleSubmitCancel}>
        <Button variant="secondary" type="submit" className="btn-create-form">
            Cancelar
          </Button>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};
export default connect(mapStateToProps)(Editar);
