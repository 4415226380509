import * as type from "./types";
import EventBus from "../common/EventBus";
import UserService from "../services/user.service";
import { history } from "../helpers/history";
import userService from "../services/user.service";

export const getDocuments = (form) => async (dispatch) => {
  dispatch({
    type: type.START_LOADING,
  });
  return UserService.getDocuments(form).then(
    (response) => {
      UserService.getProcess(form).then(
        (response) => {
          dispatch({
            type: type.FETCH_PROCESS_SUCCESS,
            payload: response.data.result,
          });
          dispatch({
            type: type.FINISH_LOADING,
          });
        },
        (error) => {
          dispatch({
            type: type.FETCH_PROCESS_FAILURE,
          });
          dispatch({
            type: type.FINISH_LOADING,
          });
          // Hace logout si recibimos un error 401 => Unauthorized
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );

      dispatch({
        type: type.FETCH_DOCUMENT_SUCCESS,
        payload: response.data.result,
      });
    },
    (error) => {
      dispatch({
        type: type.FETCH_DOCUMENT_FAILURE,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        // EventBus.dispatch("logout");
        history.push("/");
      }
    }
  );
};
export const getAllDocuments = () => async (dispatch) => {
  dispatch({
    type: type.START_LOADING,
  });
  return UserService.getAllDocuments().then(
    (response) => {
      dispatch({
        type: type.FETCH_DOCUMENT_SUCCESS,
        payload: response.data.result,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });
    },
    (error) => {
      dispatch({
        type: type.FETCH_DOCUMENT_FAILURE,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        // EventBus.dispatch("logout");
        history.push("/");
      }
    }
  );
};

export const getAllDocTemplate = () => async (dispatch) => {
  dispatch({
    type: type.START_LOADING,
  });
  return UserService.getAllDocuments().then(
    (response) => {
      UserService.getAllTemplate().then(
        (response) => {
          dispatch({
            type: type.FETCH_TEMPLATE_SUCCESS,
            payload: response.data.result,
          });
          dispatch({
            type: type.FINISH_LOADING,
          });
        },
        (error) => {
          dispatch({
            type: type.FETCH_TEMPLATE_FAILURE,
            payload: error.message,
          });
          dispatch({
            type: type.FINISH_LOADING,
          });
        }
      );
      dispatch({
        type: type.FETCH_DOCUMENT_SUCCESS,
        payload: response.data.result,
      });
    },
    (error) => {
      dispatch({
        type: type.FETCH_DOCUMENT_FAILURE,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        // EventBus.dispatch("logout");
        history.push("/");
      }
    }
  );
};

export const getDocTempVendor = () => async (dispatch) => {
  dispatch({
    type: type.START_LOADING,
  });
  return UserService.getAllDocuments().then(
    (response) => {
      UserService.getAllVendor().then(
        (response) => {
          UserService.getAllTemplate().then(
            (response) => {
              dispatch({
                type: type.FETCH_TEMPLATE_SUCCESS,
                payload: response.data.result,
              });
              dispatch({
                type: type.FINISH_LOADING,
              });
            },
            (error) => {
              dispatch({
                type: type.FETCH_TEMPLATE_FAILURE,
              });
              dispatch({
                type: type.FINISH_LOADING,
              });
            }
          );
          dispatch({
            type: type.ALL_VENDOR_SUCCESS,
            payload: response.data.result,
          });
        },
        (error) => {
          dispatch({
            type: type.ALL_VENDOR_FAILURE,
          });
          dispatch({
            type: type.FINISH_LOADING,
          });
        }
      );
      dispatch({
        type: type.FETCH_DOCUMENT_SUCCESS,
        payload: response.data.result,
      });
    },
    (error) => {
      dispatch({
        type: type.FETCH_DOCUMENT_FAILURE,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        // EventBus.dispatch("logout");
        history.push("/");
      }
    }
  );
};

export const deleteDocument = (form) => async (dispatch) => {
  dispatch({
    type: type.START_LOADING,
  });
  return UserService.deleteDocument(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });
      if(response.data.message !== 'KO'){
        window.location.reload();
      }
    },
    (error) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: error.message,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        // EventBus.dispatch("logout");
        history.push("/");
      }
    }
  );
};

export const createDoc = (form) => async (dispatch) => {
  dispatch({
    type: type.START_LOADING,
  });
  return UserService.createDocument(form).then(
    (response) => {
      userService.getAllDocuments().then(
        (response) => {
          dispatch({
            type: type.FETCH_DOCUMENT_SUCCESS,
            payload: response.data.result,
          });
          dispatch({
            type: type.FINISH_LOADING,
          });
        },
        (error) => {
          dispatch({
            type: type.FETCH_DOCUMENT_FAILURE,
          });
          dispatch({
            type: type.FINISH_LOADING,
          });
        }
      );
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
    },
    (error) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: error.message,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        // EventBus.dispatch("logout");
        history.push("/");
      }
    }
  );
};

export const uploadFileSharepoint = (file, form) => async (dispatch) => {
  dispatch({
    type: type.START_LOADING_FILE,
  });
  return UserService.uploadFile(file).then(
    (response) => {
      UserService.updateDocument(form).then(
        (response) => {
          dispatch({
            type: type.SET_MESSAGE,
            payload: response.data.message,
          });
          dispatch({
            type: type.FINISH_LOADING_FILE,
          });
          history.push("/procesos/" + form.user + "/" + form.id);
        },
        (error) => {
          dispatch({
            type: type.SET_MESSAGE,
            payload: error.message,
          });
          dispatch({
            type: type.FINISH_LOADING_FILE,
          });
          // Hace logout si recibimos un error 401 => Unauthorized
          if (error.response && error.response.status === 401) {
            console.log("error.response: ", error.response);
            console.log("error.response.status: ", error.response.status);
            EventBus.dispatch("logout");
          }
        }
      );
    },
    (error) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: error.message,
      });
      dispatch({
        type: type.FINISH_LOADING_FILE,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};

export const uploadNAFile = (form) => async (dispatch) => {
  return UserService.updateDocumentNA(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
      history.push("/procesos/" + form.user + "/" + form.id);
    },
    (error) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: error.message,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};

export const sendFormRevCloseProcess = (form) => async (dispatch) => {
  return UserService.updateDocCloseProcess(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
      window.location.reload(false);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: type.SET_MESSAGE,
        payload: message,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};
export const sendFormRev = (form) => async (dispatch) => {
  return UserService.updateDocument(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
      window.location.reload(false);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: type.SET_MESSAGE,
        payload: message,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};

export const notifyVendorDoc = (form) => async (dispatch) => {
  return UserService.notifyVendorDoc(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
      history.push("/");
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: type.SET_MESSAGE,
        payload: message,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};


export const sendMailToComplianceAllDocUploaded =
  (form) => async (dispatch) => {
    return UserService.sendMailToComplianceAllDocUploaded(form).then(
      (response) => {
        dispatch({
          type: type.SET_MESSAGE,
          payload: response.data.message,
        });
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: type.SET_MESSAGE,
          payload: message,
        });

        // Hace logout si recibimos un error 401 => Unauthorized
        if (error.response && error.response.status === 401) {
          console.log("error.response: ", error.response);
          console.log("error.response.status: ", error.response.status);
          EventBus.dispatch("logout");
        }
      }
    );
  };

export const modificarFecProxRenov = (form) => async (dispatch) => {
  return UserService.modificarFecProxRenov(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
      history.push("/");
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: type.SET_MESSAGE,
        payload: message,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        EventBus.dispatch("logout");
      }
    }
  );
};
