import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import user from "./user";
import loading from "./loading";
import process from "./process";
import vendor from "./vendor";
import vendorcategory from "./vendorcategory";
import documents from "./documents";
import templates from "./templates";

export default combineReducers({
  auth,
  message,
  loading,
  user,
  process,
  vendor,
  documents,
  templates,
  vendorcategory,
});
