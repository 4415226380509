// REACT
import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
// LIBRARIES
import { Form, Button, Row, Col, Modal } from "react-bootstrap";
// COMPONENTS
import store from "../../store";
import Header from "../Header/Header";
import { getDocTempVendor } from "../../actions/documents";
import { createProcess } from "../../actions/process";
import Loading from "../loading";
import { days, months, monthsKey, years } from "../../config/config";
import * as lib from "../../functions/functions";
import { history } from "../../helpers/history";

const Asignar = () => {
  const currentUser = store.getState().auth.user;
  const loading = store.getState().loading;
  const dispatch = useDispatch();
  const fechaactual = new Date();
  let diaactualaux = "0" + fechaactual.getDate();
  let diaactual = diaactualaux.slice(-2,diaactualaux.length);
  let anorenovacionauto = fechaactual.getFullYear()+1;

  
  let mesactual = "";
  switch(fechaactual.getMonth()) {
    case 0 : mesactual = "Enero";
    break;
    case 1 : mesactual = "Febrero";
    break;
    case 2 : mesactual = "Marzo";
    break;
    case 3 : mesactual = "Abril";
    break;
    case 4 : mesactual = "Mayo";
    break;
    case 5 : mesactual = "Junio";
    break;
    case 6 : mesactual = "Julio";
    break;
    case 7 : mesactual = "Agosto";
    break;
    case 8 : mesactual = "Septiembre";
    break;
    case 9 : mesactual = "Octubre";
    break;
    case 10 : mesactual = "Noviembre";
    break;
    default : mesactual = "Diciembre";
    break;
  }

  let mesactualvaliddate = "";
  switch(fechaactual.getMonth()) {
    case 0 : mesactualvaliddate = "01";
    break;
    case 1 : mesactualvaliddate = "02";
    break;
    case 2 : mesactualvaliddate = "03";
    break;
    case 3 : mesactualvaliddate = "04";
    break;
    case 4 : mesactualvaliddate = "05";
    break;
    case 5 : mesactualvaliddate = "06";
    break;
    case 6 : mesactualvaliddate = "07";
    break;
    case 7 : mesactualvaliddate = "08";
    break;
    case 8 : mesactualvaliddate = "09";
    break;
    case 9 : mesactualvaliddate = "10";
    break;
    case 10 : mesactualvaliddate = "11";
    break;
    default : mesactualvaliddate = "12";
    break;
  }

  const [formDataProv, setformDataProv] = useState({
    vendorID: "",
    vendorName: "",
    processDescription: "",
    processType: "Alta",
    homologationType: "",
    validDate: diaactual + "/" + mesactualvaliddate + "/" + anorenovacionauto,
    day: diaactual,
    month: mesactual,
    year: anorenovacionauto,
    plantilla: "",
  });

  const [provList, setprovList] = useState();
  const [filteredProvList, setfilteredProvList] = useState();

  const [uniqueType, setuniqueType] = useState();
  const [uniqueName, setuniqueName] = useState();
  const [selectedCheckBox, setselectedCheckBox] = useState();
  const doc = store.getState().documents.documents;
  const templates = store.getState().templates.templates;

  useEffect(() => {
    dispatch(getDocTempVendor());
  }, [dispatch]);

  useEffect(() => {
    const tempUniqueType = [
      ...new Set(templates.map((doc) => doc.tipoHomologacion)),
    ];

    setuniqueName(doc);
    setuniqueType(tempUniqueType);

    // Tenemos que crear un estado para los checks de los documentos e inicializarlo aquí.
    let tempSelectedCheckBox = {};
    doc.map((doc) => {
      const id = doc.idDocumento;
      tempSelectedCheckBox[id] = false;
      return tempSelectedCheckBox;
    });
    setselectedCheckBox(tempSelectedCheckBox);
    setprovList(store.getState().vendor.vendor);
    setfilteredProvList(store.getState().vendor.vendor);
  }, [templates, doc]);

  lib.userAccessbyRoles(currentUser, "ADMIN", "USER");

  function filterDoc(template) {
    const result = templates.filter((doc) => doc.tipoHomologacion === template);

    var selected = selectedCheckBox;

    // Reseteamos selected del posible estado anterior
    for (const key in selected) {
      selected[key] = false;
    }

    result.map((doc) => {
      const name = doc.idDocumento;
      selected[name] = true;
      return selected;
    });
    setselectedCheckBox(selected);
  }

  function filterProveedores(estado) {
    const result = provList.filter((prov) => prov.estadoProveedor === estado);
    setfilteredProvList(result);
  }

  const [show, setShow] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShowModal2(false);
  const handleShow2 = () => setShowModal2(true);

  function handleSubmit(e) {
    e.preventDefault();

    var docForm = [];

    for (const key in selectedCheckBox) {
      if (selectedCheckBox[key]) {
        docForm.push({ idDocumento: key });
      }
    }
    const finalForm = {
      ...formDataProv,
      documents: docForm,
    };
   
    // dispatch(createProcess(finalForm));
    if (isValidDate(finalForm.validDate) === false){
      handleShow();
    }else if (docForm.length === 0){
      handleShow2();
    }else{
      dispatch(createProcess(finalForm));
    }

    
  }

  function handleSubmitCancel() {
    history.push("/");
  }

  function isValidDate(value, userFormat) {

    // Set default format if format is not provided
    userFormat = userFormat || 'dd/mm/yyyy';
  
    // Find custom delimiter by excluding the
    // month, day and year characters
    var delimiter = /[^dmy]/.exec(userFormat)[0];
  
    // Create an array with month, day and year
    // so we know the format by index
    var theFormat = userFormat.split(delimiter);
  
    // Get the user date now that we know the delimiter
    var theDate = value.split(delimiter);
  
    function isDate(date, format) {
      var m, d, y, i = 0, len = format.length, f;
      for (i; i < len; i++) {
        f = format[i];
        if (/d/.test(f)) d = date[i];
        if (/m/.test(f)) m = date[i];
        if (/y/.test(f)) y = date[i];
      }
      return (
        m > 0 && m < 13 &&
        y && y.length === 4 &&
        d > 0 &&
        // Is it a valid day of the month?
        d <= (new Date(y, m, 0)).getDate()
      );
    }
  
    return isDate(theDate, theFormat);
  
  }
  

  return loading ? (
    <Loading />
  ) : (
    <div className="pageContainer">
      <Header />
      <div className="formContainer">
        <h2>Asignar Proceso</h2>
        <Form>
          <Form.Group className="mb-3">
            <Row className="g-2">
              <Col md>
                <Form.Label as="h6" sm={2}>
                  Filtrado de proveedores por estado
                </Form.Label>
                <Form.Check
                  required="required"
                  inline
                  type="radio"
                  label="Pdte asignar"
                  name="FiltradoProveedores"
                  id="FiltradoProveedores1"
                  onChange={() => {
                    filterProveedores("Pdte asignar");
                  }}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="En Curso"
                  name="FiltradoProveedores"
                  id="FiltradoProveedores2"
                  onChange={() => {
                    filterProveedores("En Curso");
                  }}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Pdte validación"
                  name="FiltradoProveedores"
                  id="FiltradoProveedores3"
                  onChange={() => {
                    filterProveedores("Pdte validación");
                  }}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Homologado"
                  name="FiltradoProveedores"
                  id="FiltradoProveedores4"
                  onChange={() => {
                    filterProveedores("Homologado");
                  }}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Deshomologado"
                  name="FiltradoProveedores"
                  id="FiltradoProveedores5"
                  onChange={() => {
                    filterProveedores("Deshomologado");
                  }}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Exento"
                  name="FiltradoProveedores"
                  id="FiltradoProveedores6"
                  onChange={() => {
                    filterProveedores("Exento");
                  }}
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formVendorName">
            <Row className="g-2">
              <Col>
                <Form.Label as="h5">Nombre Proveedor</Form.Label>
                <Form.Select
                  required="required"
                  value={formDataProv.vendorName}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      vendorName: e.target.value,
                      vendorID: store
                        .getState()
                        .vendor.vendor.filter(
                          (vend) => vend.nombreProveedor === e.target.value
                        )[0].idProveedor,
                    })
                  }
                >
                  <option value="" hidden>
                    ---
                  </option>
                  {filteredProvList?.map((vendor) => (
                    <option
                      key={vendor.idProveedor}
                      value={vendor.nombreProveedor}
                    >
                      {vendor.nombreProveedor.charAt(0).toUpperCase() +
                        vendor.nombreProveedor.slice(1)}
                    </option>
                  ))}
                </Form.Select>
              </Col>

              {/* <Col>
                <Form.Label as="h5">Título Homologación</Form.Label>
                <FloatingLabel label="Título">
                  <Form.Control
                    type="text"
                    placeholder="Descripcion"
                    onChange={(e) =>
                      setformDataProv({
                        ...formDataProv,
                        processDescription: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col> */}
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Row className="g-2">
              {/* <Col md>
                <Form.Label as="h5" sm={2}>
                  Proceso
                </Form.Label>
                <Form.Check
                  required="required"
                  inline
                  type="radio"
                  label="Alta"
                  name="Proceso"
                  id="Proceso1"
                  onChange={() =>
                    setformDataProv({
                      ...formDataProv,
                      processType: "Alta",
                    })
                  }
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Renovacion"
                  name="Proceso"
                  id="Proceso2"
                  onChange={() => {
                    setformDataProv({
                      ...formDataProv,
                      processType: "Renovacion",
                      plantilla: "Renovación",
                    });
                    filterDoc("Renovación");
                  }}
                />
              </Col> */}
              <Col md>
                <Row className="g-2">
                  <Col md>
                    <Form.Label as="h5" sm={2}>
                      Fecha fin de validez
                    </Form.Label>
                  </Col>
                </Row>
                <Row className="g-2 filaFechas">
                  <Col className="selectDay">
                    <Form.Select
                      required="required"
                      value={formDataProv.day}
                      onChange={(e) =>
                        setformDataProv({
                          ...formDataProv,
                          day: e.target.value,
                          validDate:
                            e.target.value +
                            "/" +
                            monthsKey[formDataProv.month] +
                            "/" +
                            formDataProv.year,
                        })
                      }
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      {days.map((days) => (
                        <option key={days} value={days}>
                          {days}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col className="selectMonth">
                    <Form.Select
                      required="required"
                      value={formDataProv.month}
                      onChange={(e) =>
                        setformDataProv({
                          ...formDataProv,
                          month: e.target.value,
                          validDate:
                            formDataProv.day +
                            "/" +
                            monthsKey[e.target.value] +
                            "/" +
                            formDataProv.year,
                        })
                      }
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      {months.map((months) => (
                        <option key={months} value={months}>
                          {months}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col className="selectYear">
                    <Form.Select
                      required="required"
                      value={formDataProv.year}
                      onChange={(e) =>
                        setformDataProv({
                          ...formDataProv,
                          year: e.target.value,
                          validDate:
                            formDataProv.day +
                            "/" +
                            monthsKey[formDataProv.month] +
                            "/" +
                            e.target.value,
                        })
                      }
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      {years.map((years) => (
                        <option key={years} value={years}>
                          {years}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group>
            <Form.Label as="h5">Plantilla</Form.Label>
            <Form.Select
              value={formDataProv.plantilla}
              onChange={(e) => {
                setformDataProv({
                  ...formDataProv,
                  plantilla: e.target.value,
                });
                if (e.target.value === "Renovación"){
                  setformDataProv({
                    ...formDataProv,
                    plantilla: e.target.value,
                    processType: "Renovación"
                  })} else {setformDataProv({
                    ...formDataProv,
                    plantilla: e.target.value,
                    processType: "Alta"
                  })};
                filterDoc(e.target.value);
              }}
            >
              <option value="" hidden>
                ---
              </option>
              {uniqueType?.map((vendor) => (
                <option key={vendor} value={vendor}>
                  {vendor}
                </option>
              ))}
            </Form.Select>
            {uniqueName?.map((doc) => (
              <Form.Check
                key={doc.nombre}
                type="checkbox"
                label={doc.nombre}
                name={doc.nombre}
                id={doc.nombre}
                checked={selectedCheckBox[doc.idDocumento]}
                onChange={(e) =>
                  setselectedCheckBox({
                    ...selectedCheckBox,
                    [doc.idDocumento]: !selectedCheckBox[doc.idDocumento],
                  })
                }
              />
            ))}
          </Form.Group>
          <Button variant="secondary" type="submit" className="btn-create-form">
            Asignar
          </Button>
        </Form>
        <Form onSubmit={handleSubmitCancel}>
        <Button variant="secondary" type="submit" className="btn-create-form">
            Cancelar
          </Button>
        </Form>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Error al asignar proceso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          La fecha fin validez no es válida
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal show={showModal2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Error al asignar nuevo proceso</Modal.Title>
        </Modal.Header>
        <Modal.Body>Por favor selecciona al menos un documento</Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};
export default connect(mapStateToProps)(Asignar);
