import * as config from "../config/config";
import { history } from "../helpers/history";

export function push(route) {
  return history.push(route);
}

export function userAccessbyRol(currentUser, rol) {
  if (!currentUser) {
    return history.push("/login");
  } else {
    if (currentUser.role !== config.ROLES[rol]) {
      return history.push("/");
    }
  }
}
export function userAccess(currentUser) {
  if (!currentUser) {
    return history.push("/login");
  }
}

export function userAccessbyRoles(currentUser, rol1, rol2) {
  if (!currentUser) {
    return history.push("/login");
  } else {
    if (![config.ROLES[rol1], config.ROLES[rol2]].includes(currentUser.role)) {
      return history.push("/");
    }
  }
}

export function generatePassword() {
  var length = 8,
    charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@$?_-",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}
