import * as type from "./types";
import UserService from "../services/user.service";
import { history } from "../helpers/history";

export const deleteTemplate = (form) => async (dispatch) => {
  dispatch({
    type: type.START_LOADING,
  });
  return UserService.deleteTemplate(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });
      history.push("/");
    },
    (error) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: error.message,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        // EventBus.dispatch("logout");
        history.push("/");
      }
    }
  );
};

export const createNewTemplate = (form) => async (dispatch) => {
  dispatch({
    type: type.START_LOADING,
  });
  return UserService.createNewTemplate(form).then(
    (response) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: response.data.message,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });
      history.push("/");
    },
    (error) => {
      dispatch({
        type: type.SET_MESSAGE,
        payload: error.message,
      });
      dispatch({
        type: type.FINISH_LOADING,
      });

      // Hace logout si recibimos un error 401 => Unauthorized
      if (error.response && error.response.status === 401) {
        console.log("error.response: ", error.response);
        console.log("error.response.status: ", error.response.status);
        history.push("/");
      }
    }
  );
};
