import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
// LIBRARIES
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
// COMPONENTS
import Loading from "../loading";
import store from "../../store";
import { history } from "../../helpers/history";

import {
  deleteDocument,
  getAllDocTemplate,
  createDoc,
} from "../../actions/documents";
import { createNewTemplate, deleteTemplate } from "../../actions/templates";

import Header from "../Header/Header";
import * as lib from "../../functions/functions";
const Plantilla = () => {
  const dispatch = useDispatch();

  const currentUser = store.getState().auth.user;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModal2, setshowModal2] = useState(false);
  const handleClose2 = () => setshowModal2(false);
  const handleShow2 = () => setshowModal2(true);
  const [showModal3, setshowModal3] = useState(false);
  const handleClose3 = () => setshowModal3(false);
  const handleShow3 = () => setshowModal3(true);

  useEffect(() => {
    dispatch(getAllDocTemplate());
  }, [dispatch]);

  lib.userAccessbyRol(currentUser, "ADMIN");
  let finalForm = [];
  const doc = store.getState().documents.documents;
  const templates = store.getState().templates.templates;

  const [uniqueTypeEditar, setuniqueTypeEditar] = useState();

  const [uniqueType, setuniqueType] = useState();

  const [templateForm, settemplateForm] = useState({
    nombrePlantillaEdicion:"",
    nombrePlantilla: "",
  });

  const [deleteTemplateForm, setdeleteTemplateForm] = useState({
    template: "",
  });
  const [deleteDocumentForm, setdeleteDocumentForm] = useState({
    document: "",
  });

  const [addDocForm, setaddDocForm] = useState({
    nombre: "",
    descripcion: "",
  });

  useEffect(() => {
    const tempUniqueType = [
      ...new Set(templates.map((temp) => temp.tipoHomologacion)),
    ];
    var index = tempUniqueType.indexOf("Renovación");
    if (index !== -1) {
      tempUniqueType.splice(index, 1);
    }
    setuniqueType(tempUniqueType);
  }, [templates]);

  useEffect(() => {
    const tempUniqueTypeEditar = [
      ...new Set(templates.map((temp) => temp.tipoHomologacion)),
    ];
    // var index = tempUniqueType.indexOf("Renovación");
    // if (index !== -1) {
    //   tempUniqueType.splice(index, 1);
    // }
    setuniqueTypeEditar(tempUniqueTypeEditar);
  }, [templates]);

  function handleSubmit(e) {
    e.preventDefault();
    // eleminimaos todos los que vengan a false
    for (var document in templateForm) {
      if (templateForm[document] === false) {
        delete templateForm[document];
      }
    }
    if (Object.keys(templateForm).length === 1) {
      handleShow2();
    } else if (uniqueType.includes(templateForm.nombrePlantilla) && templateForm.nombrePlantillaEdicion === "Nueva") {
      handleShow();
    } else {
      // creamos un formulario más sencillo para el backend

      const keys = Object.keys(templateForm);
      keys.forEach((key, index) => {
        const result = doc.filter((doc) => doc.nombre === key);
        const id = result[0]?.idDocumento;
        finalForm.push({
          id: id,
          tipoHomologacion: templateForm["nombrePlantilla"],
          tipoEdicionCreacion: templateForm["nombrePlantillaEdicion"],
        });
      });
      finalForm.shift();
      finalForm.shift();
      // aquí se hace el dispatch con el formulario finalForm
      dispatch(createNewTemplate(finalForm));
    }
  }

  function addDoc(e) {
    e.preventDefault();

    // TENEMOS QUE HACER UN DISPATCH
    dispatch(createDoc(addDocForm));

    setaddDocForm({ nombre: "", descripcion: "" });
    settemplateForm({ nombrePlantilla: "" });
  }

  function handleDeleteTemplate(e) {
    e.preventDefault();
    dispatch(deleteTemplate(deleteTemplateForm));
  }
  function handleDeleteDocument(e) {
    e.preventDefault();
    const id = parseInt(deleteDocumentForm.document);
    const result = templates.filter((temp) => temp.idDocumento === id);
    if (result[0]?.tipoHomologacion) {
      handleShow3();
    } else {
      dispatch(deleteDocument(deleteDocumentForm));
    }
  }

  function handleSubmitCancel() {
    history.push("/");
  }


  const loading = store.getState().loading;
  const msg = store.getState().message?.message;
  return loading ? (
    <Loading />
  ) : (
    <div className="pageContainer">
      <Header />
      {msg === 'KO' ? (<div className="alert alert-danger" role = "alert">ERROR: No se ha podido eliminar el documento ya que pertenece por lo menos a un proceso de homologación</div>) : (<div></div>)}
      <div className="formContainer">
        <h2>Administrar plantillas</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
          <Col>
                    <Form.Select
                      required="required"
                      value={templateForm.nombrePlantillaEdicion}
                      onChange={(e) => {
                        if (e.target.value !== "Nueva"){
                          settemplateForm({
                            ...templateForm,
                            nombrePlantillaEdicion: e.target.value,
                            nombrePlantilla: e.target.value,
                          })
                        } else {
                          settemplateForm({
                            ...templateForm,
                            nombrePlantillaEdicion: e.target.value,
                            nombrePlantilla: "",
                          })
                        }
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      <option value="Nueva">
                        Crear Nueva Plantilla
                      </option>
                      {uniqueTypeEditar?.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      )
                      )}
                    </Form.Select>
                  </Col>
            {/* <Form.Check
              key="Renovación"
              type="checkbox"
              label="Editar plantilla de renovación"
              name="Renovación"
              id="Renovación"
              onChange={(e) => {
                if (templateForm.nombrePlantilla === "Renovación") {
                  settemplateForm({
                    ...templateForm,
                    nombrePlantilla: "",
                  });
                } else {
                  settemplateForm({
                    ...templateForm,
                    nombrePlantilla: "Renovación",
                  });
                }
              }}
            /> */}

            <Form.Control
              className="inputPlantilla"
              type="text"
              placeholder="Nombre plantilla"
              disabled={templateForm.nombrePlantillaEdicion !== "Nueva"}
              required="required"
              value={templateForm.nombrePlantilla}
              onChange={(e) => {
                if (templateForm.nombrePlantillaEdicion !== "Nueva") {
                  settemplateForm({
                    ...templateForm,
                    nombrePlantilla: templateForm.nombrePlantillaEdicion,
                  });
                } else {
                  settemplateForm({
                    ...templateForm,
                    nombrePlantilla: e.target.value,
                  });
                }
              }}
            /> 
          </Form.Group>
          <Form.Group>
            {doc?.map((doc) => (
              <Form.Check
                key={doc.nombre}
                type="checkbox"
                label={doc.nombre}
                name={doc.nombre}
                id={doc.nombre}
                onChange={(e) =>
                  settemplateForm({
                    ...templateForm,
                    [doc.nombre]: !templateForm[doc.nombre],
                  })
                }
              />
            ))}
          </Form.Group>
          <Button variant="secondary" type="submit" className="btn-create-form">
            {templateForm.nombrePlantillaEdicion !== "Nueva" ? "Editar" : "Crear"}
          </Button>
        </Form>
        <Form onSubmit={addDoc}>
          <Form.Group>
            <h5>Añadir documento</h5>
            <Row>
              <Button
                variant="secondary"
                type="submit"
                className="btn-create-form"
              >
                +
              </Button>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Nombre"
                  required="required"
                  value={addDocForm.nombre}
                  onChange={(e) =>
                    setaddDocForm({
                      ...addDocForm,
                      nombre: e.target.value,
                    })
                  }
                />
              </Col>

              <Col>
                <Form.Control
                  type="text"
                  placeholder="Descripción"
                  required="required"
                  value={addDocForm.descripcion}
                  onChange={(e) =>
                    setaddDocForm({
                      ...addDocForm,
                      descripcion: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </div>
      <div className="formContainer">
        <Row>
          <Col>
            <h4>Plantilla</h4>
            <Form
              onSubmit={handleDeleteTemplate}
              className="deleteTemplateForm"
            >
              <Form.Group>
                <Row>
                  <Button
                    variant="secondary"
                    type="submit"
                    className="btn-create-form"
                  >
                    Eliminar
                  </Button>

                  <Col>
                    <Form.Select
                      required="required"
                      value={deleteTemplateForm.template}
                      onChange={(e) => {
                        setdeleteTemplateForm({
                          ...deleteTemplateForm,
                          template: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      {uniqueType?.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
          <Col>
            <h4>Documento</h4>
            <Form
              onSubmit={handleDeleteDocument}
              className="deleteTemplateForm"
            >
              <Form.Group>
                <Row>
                  <Button
                    variant="secondary"
                    type="submit"
                    className="btn-create-form"
                  >
                    Eliminar
                  </Button>

                  <Col>
                    <Form.Select
                      required="required"
                      value={deleteDocumentForm.document}
                      onChange={(e) => {
                        setdeleteDocumentForm({
                          ...deleteDocumentForm,
                          document: e.target.value,
                        });
                      }}
                    >
                      <option value="" hidden>
                        ---
                      </option>
                      {doc?.map((doc) => (
                        <option key={doc.idDocumento} value={doc.idDocumento}>
                          {doc.nombre}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </div>
      <div className="formContainer">
      <Form onSubmit={handleSubmitCancel}>
      <Button variant="secondary" type="submit" className="btn-create-form">
            Cancelar
          </Button>
        </Form>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Error al crear nueva plantilla</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          La plantilla que estás queriendo introducir ya existe en el sistema
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal show={showModal2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Error al crear nueva plantilla</Modal.Title>
        </Modal.Header>
        <Modal.Body>Por favor selecciona al menos un documento</Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal show={showModal3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Error al eliminar documento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          El documento que quieres borrar pertenece por lo menos a una
          plantilla. Solo se pueden borrar documentos que NO pertenzcan a
          ninguna plantilla.
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};
export default connect(mapStateToProps)(Plantilla);
