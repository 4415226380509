// REACT
import React, { useEffect, useState } from "react";
import store from "../../store";

// LIBRARIES
import { useDispatch,connect } from "react-redux";
import { Form, Button, Row, Col } from "react-bootstrap";
// COMPONENTS
import { createVendor } from "../../actions/vendor";
import { getAllCategoryVendor } from "../../actions/vendor";
import Header from "../Header/Header";
import Loading from "../loading";
// FUNCTIONS
import * as lib from "../../functions/functions";
import { history } from "../../helpers/history";

const Crear = () => {
  const currentUser = store.getState().auth.user;
  const dispatch = useDispatch();
  lib.userAccessbyRoles(currentUser, "ADMIN", "USER");
  const loading = store.getState().loading;
  const msg = store.getState().message?.message;

  useEffect(() => {
    dispatch(getAllCategoryVendor());
  }, [dispatch]);
  
  const [formDataProv, setformDataProv] = useState({
    vendorname: "",
    username: "",
    vendormail: "",
    ownermail: "",
    vendorcategory: "",
  });

  // function handleSubmit(e) {
  //   e.preventDefault();

  //   dispatch(createVendor(formDataProv));

  //   setformDataProv({
  //     vendorname: "",
  //     username: "",
  //     vendormail: "",
  //     ownermail: "",
  //     vendorcategory: "",
  //   });
  // }

  function handleSubmit(e) {
    e.preventDefault();
    let variable1 = formDataProv.vendorname;
    let variable2 = formDataProv.username;
    let variable3 = formDataProv.vendormail;
    let variable4 = formDataProv.ownermail;
    let variable5 = formDataProv.vendorcategory;
    dispatch(createVendor(formDataProv));
  

//console.log(msg);


if (msg === 'OK') { 
  setformDataProv({
    vendorname: "",
    username: "",
    vendormail: "",
    ownermail: "",
    vendorcategory: "",
  });}


if (msg === 'KO') { 
      setformDataProv({
        vendorname: variable1,
        username: variable2,
        vendormail: variable3,
        ownermail: variable4,
        vendorcategory: variable5,
      });}
  
  
}

function handleSubmitCancel() {
  history.push("/");
}


  

  
  return loading ? (
    <Loading />
  ) : (
    <div className="pageContainer">
      <Header />
      {msg === 'KO' ? (<div className="alert alert-danger" role = "alert">ERROR: No se ha podido crear el proveedor porque ya existe una cuenta con ese nombre.</div>) : (<div></div>)}
      <div className="formContainer">
        <h2>Crear Nuevo Proveedor</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Row>
              <Col>
                <Form.Label>Nombre Proveedor</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Introducir Nombre del proveedor"
                  required="required"
                  value={formDataProv.vendorname}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      vendorname: e.target.value,
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Nombre de la cuenta</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Introducir Nombre de la cuenta"
                  required="required"
                  value={formDataProv.username}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      username: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Correo proveedor</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Dirección de correo"
                  required="required"
                  value={formDataProv.vendormail}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      vendormail: e.target.value,
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Correo del owner</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Introducir Correo del owner"
                  required="required"
                  value={formDataProv.ownermail}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      ownermail: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Label>Categoría del proveedor</Form.Label>
                <Form.Select
                  required="required"
                  value={formDataProv.vendorcategory}
                  onChange={(e) =>
                    setformDataProv({
                      ...formDataProv,
                      vendorcategory: e.target.value,
                    })
                  }
                >
                  <option value="" hidden>
                    ---
                  </option>
                  {store.getState().vendorcategory?.vendorcategory.map((vendorcategory) => (
                    <option key={vendorcategory.categoriaProveedor} value={vendorcategory.categoriaProveedor}>
                    {" "}
                    {vendorcategory.categoriaProveedor}{" "}
                  </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </Form.Group>
          <Button variant="secondary" type="submit" className="btn-create-form">
            Crear Proveedor
          </Button>
        </Form>
        <Form onSubmit={handleSubmitCancel}>
        <Button variant="secondary" type="submit" className="btn-create-form">
            Cancelar
          </Button>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

export default connect(mapStateToProps)(Crear);
