import {
  FETCH_DOCUMENT_SUCCESS,
  FETCH_DOCUMENT_FAILURE,
  START_LOADING_FILE,
  FINISH_LOADING_FILE,
  FILE_DOWNLOAD_FAILURE,
  FILE_DOWNLOAD_SUCCESS,
} from "../actions/types";

const initialState = {
  documents: [],
  docLoading: false,
};

export default function documents(documents = initialState, action) {
  switch (action.type) {
    case FETCH_DOCUMENT_SUCCESS:
      return {
        ...documents,
        documents: action.payload,
      };
    case FETCH_DOCUMENT_FAILURE:
      return {
        ...documents,
      };
    case START_LOADING_FILE:
      return {
        ...documents,
        docLoading: true,
      };
    case FINISH_LOADING_FILE:
      return {
        ...documents,
        docLoading: false,
      };
    case FILE_DOWNLOAD_SUCCESS:
      return {
        ...documents,
        files: action.payload,
      };
    case FILE_DOWNLOAD_FAILURE:
      return {
        ...documents,
      };

    default:
      return documents;
  }
}
